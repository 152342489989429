.modalMain {
    padding-top: 25px;
    padding-left: 46px;
    padding-right: 25px;
    min-width: 700px;
}

.top {
    display: flex;
}

.title {
    padding-top: 10px;
    font-size: 20px;
    font-weight: 600;
    flex-basis: 0;
    flex-grow: 1;
    white-space: nowrap;
    padding-right: 80px;
}

.onOff {
    flex-basis: 0;
    flex-grow: 3;
    padding: 10px 0 0 0;
}

.closer {
    padding-top: 2px;
    padding-right: 2px;
    display: flex;
    justify-content: flex-end;
}

.body {
    font-size: 12px;
    color: #868E96;
    margin-left: 80px;
    margin-right: 50px;
}

.timeSect {
    margin-top: 0px;
    margin-bottom: 30px;
}

.timeLabel {
    margin-bottom: 9px;
    margin-left: 2px;
}

.timeSpan {
    margin-top: 0px;
    margin-left: 33px;
}

.thresholdSect {
    margin-bottom: 30px;
}

.thresholdLabel {
    margin-bottom: 10px;
    margin-left: 2px;
}

.thresholdTime {
    margin-top: 0px;
    margin-left: 33px;
    display: flex;
}

.upper {}

.wave {
    font-size: 11px;
    margin: 6px 10px 0 13px;
}

.lower {}

.selectUpper {
    width: 105px;
}

.selectLower {
    width: 105px;
}

.hideThreshold {}

.separator {
    align-self: stretch;
    background-color: white;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 1px;
}

.areaSect {
    margin: 15px 0px;
}

.areaLabel {
    margin-bottom: 10px;
    margin-left: 2px;
}

.areaFrame {
    border: solid 1px #d7dbec;
    border-radius: 4px;
    margin-left: 33px;
}

.areaTop {
    margin-top: 11px;
    margin-left: 16px;
}

.areaList {
    margin-left: 16px;
    padding: 5px 15px 5px 15px;
}

.table {
    margin-bottom: 20px;
    margin-left: 4px;
    border-collapse: collapse;
}

.dataCol {
    width: 216px;
    height: 27px;
    text-overflow: ellipsis;
    overflow: auto;
    white-space: nowrap;
}

.emptyCol {
    width: 70px;
}

.footer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 17px;
    margin-bottom: 23px;
}

.left {
    margin-right: auto;
    margin-left: auto;
}

.center {
    display: flex;
    flex-wrap: wrap;
}

.space {
    width: 33px;
    margin: auto;
}

.right {
    margin-right: auto;
    margin-left: auto;
}

.timespan {
    display: flex;
    flex-wrap: wrap;
}

.tmpickStart {
    margin-left: 30px;
}

.tpStHour {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: solid 1px #D7DBEC;
    border-radius: 4px;
    color: #868E96;
    background-image: url(/public/img/chevron-down_icon.png);
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: right 4px center;
    width: 38px;
    height: 32px;
    padding-left: 3px;
}

.tpStMinute {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: solid 1px #D7DBEC;
    border-radius: 4px;
    color: #868E96;
    background-image: url(/public/img/chevron-down_icon.png);
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: right 4px center;
    width: 38px;
    height: 32px;
    padding-left: 3px;
}

.tmColon {
    margin: 2px;
}

.tmWave {
    margin: 5px;
}

.tmpickEnd {}

.tpEdHour {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: solid 1px #D7DBEC;
    border-radius: 4px;
    color: #868E96;
    background-image: url(/public/img/chevron-down_icon.png);
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: right 4px center;
    width: 38px;
    height: 32px;
    padding-left: 3px;
}

.tpEdMinute {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: solid 1px #D7DBEC;
    border-radius: 4px;
    color: #868E96;
    background-image: url(/public/img/chevron-down_icon.png);
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: right 4px center;
    width: 38px;
    height: 32px;
    padding-left: 3px;
}

.buyOrNotBox {
    margin: 4px 0 4px 33px;
    width: 200px;
}