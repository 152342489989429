.modalMain {
    padding: 8px 8px 0px 41px;
    margin-bottom: 25px;
}

.top {
    display: flex;
}

.title {
    padding: 12px 0px 12px 0px;
    font-size: 20px;
    font-weight: 600;
    flex-basis: 0;
    flex-grow: 1;
}

.closer {
    padding-top: 2px;
    padding-right: 2px;
    display: flex;
    justify-content: flex-end;
}

.body {
    font-size: 14px;
    color: #131523;
    margin-left: 28px;
    margin-right: 15px;
}

.table {
    max-width: 1262px;
    margin: 0 10px 10px 10px;
    justify-content: center;
    text-align: center;
    border-collapse: collapse;
}
.table th {
    padding: 0px 10px 8px 10px;
    font-size: 12px;
    font-weight: 300;
    color: #7E84A3;
}
.table td {
    padding: 8px 10px;
    /*border-top: none;*/
    border-bottom: solid 1px #F1F1F5;
    cursor: pointer;
}
.usedate {
    width: 92px;
}
.shopname {
    width: 230px;
}
.layoutname {
    width: 244px;
}
.datespan {
    width: 180px;
}
.timespan {
    width: 104px;
}
.weekdays {
    width: 90px;
}
.clerk {
    width: 34px;
}
.threshold {
    width: 64px;
}
.setlink {
    width: 24px;
}
.dataColEllipsis {
    max-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.chevron {
    color: #007bff;
    cursor: pointer;
}

.trOver {
    background-color: #F1F1F5;
    cursor: pointer;
}
