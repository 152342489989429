.entry {
    margin: 0 20px 10px 20px;
    padding: 10px 0 10px 20px;
    box-shadow: inset 0 0 6px #00000029;
    border-radius: 6px;
    background-color: #fAfAff;
}

.intitle {
    font-size: 1.6rem;
    text-align: start;
    font-weight: 700;
    margin: 10px 0 10px 20px;
}

.infoRow {
    font-size: 1.2rem;
    color: #868E96;
    display: flex;
    margin: 15px 0 10px 60px;
    text-align: start;
}

.infoStart {
    width: 92px;
    flex-basis: auto;
}

.numberInput input {
    width: 70px;
    border: solid 1px #D7DBEC;
    border-radius: 4px;
    padding: 4px 10px;
    color: #131523;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
}

.numberInput input:focus {
    outline: 2px #007bff solid;
}

.info2nd {
    width: 402px;
    flex-basis: auto;
}

.nameInput input {
    width: 380px;
    border: solid 1px #D7DBEC;
    border-radius: 4px;
    padding: 4px 10px;
    color: #131523;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
}

.nameInput input:focus {
    outline: 2px #007bff solid;
}

.info3rd {
    width: 380px;
    flex-basis: auto;
}

.grpSelector select {
    border: solid 1px #D7DBEC;
    border-radius: 4px;
    padding: 4px 10px 5px 10px;
    appearance: none;
    -webkit-appearance: none;
    background-image: url(./img/chevron-down_icon.png);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    color: #868e96;
    width: 380px;
    height: 28px;
    font-size: 1.2rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
}

.grpSelector select:focus {
    outline: 2px #007bff solid;
}

.infoEnd {
    width: 150px;
    flex-basis: auto;
}

.typSelector select {
    width: 150px;
    border: solid 1px #D7DBEC;
    border-radius: 4px;
    padding: 4px 10px 5px 10px;
    appearance: none;
    -webkit-appearance: none;
    background-image: url(./img/chevron-down_icon.png);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    color: #868e96;
    height: 28px;
    font-size: 1.2rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
}

.space {
    width: 15px;
    flex-basis: auto;
}

.label {
    padding: 0 0 6px 10px;
}

.selector {}

.textbox {
    border: solid 1px #D7DBEC;
    border-radius: 4px;
    height: 29px;
}

.textName {
    position: relative;
    left: 9px;
    top: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}

.areaSelect {
    appearance: none;
    -webkit-appearance: none;
    background-image: url(./img/chevron-down_icon.png);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    border: 1px solid #d7dbec;
    border-radius: 4px;
    color: #868e96;
    width: 380px;
    height: 29px;
    font-size: 1.2rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    padding-left: 15px;
}

.areaSelect:focus {
    outline: 2px #007bff solid;
}

.main {
    display: flex;
    margin-top: 15px;
}

.largeMap {
    margin: 0 0 0 40px;
}

.screen {
    width: 650px;
    height: 470px;
    border: solid 2px #707070;
    border-radius: 4px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 16%);
}

.canvasWrap {
    position: relative;
    padding: 0;
    margin: 0;
    box-sizing: content-box;
    width: 650px;
}

.canvasWrap::before {
    content: "";
    display: block;
    padding-top: 0%;
}

.bgImgCanvas {
    z-index: 1;
    position: absolute;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
}

.drawCanvas {
    z-index: 6;
    position: absolute;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
}

.animeCanvas {
    z-index: 11;
    position: absolute;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
}

.comment {
    color: #868E96;
    font-size: 1.3rem;
    text-align: start;
    margin-top: 8px;
}

.control {
    height: 60px;
    margin: 20px 0 0px 0;
}

.smallMap {
    margin: 0 0 0 30px;
}

.sideWrap {
    position: relative;
    padding: 0;
    margin: 0;
    box-sizing: content-box;
    width: 300px;
    height: 217px;
    border: solid 2px #5A607F;
}

.sideWrap::before {
    content: "";
    display: block;
    padding-top: 0%;
}

.glassCanvas {
    z-index: 1;
    position: absolute;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
}

.smallCanvas {
    z-index: 6;
    position: absolute;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
}

.moveCanvas {
    z-index: 11;
    position: absolute;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
}

.buttons {}

.rowTop {
    margin-top: 20px;
    height: 160px;
    padding-left: 30px;
    border: solid 2px #F1F1F5;
}

.rowBottom {
    margin-top: 10px;
}

.spc {
    padding: 10px 15px;
}

.spc2 {
    width: 150px;
    padding: 0 100px;
}

.dialogPane {
    width: 450px;
    height: 130px;
    border-radius: 6px;
}

.dialogHead {
    font-size: 1.8rem;
    padding: 20px 0 0px 40px;
}

.dialogBody {
    padding: 0 0 22px 50px;
}

.confirm {
    color: #868E96;
    font-size: 1.6rem;
}

.dialogBottom {
    text-align: end;
    padding: 0 50px 0 0px;
}

.errMsg {
    font-size: 1.2rem;
    color: red;
    padding-left: 12px;
    padding-top: 5px;
    height: 18px;
}