.bodyLabel {
    font-size: 1.6rem;
    text-align: start;
    margin: 10px 0 10px 50px;
}

.lsetList {
    width: 1000px;
    margin: 0 30px 0 50px;
}

.accordionItem {
    border: solid 1px rgb(0 0 0 / 13%)
}

.accItems {
    padding: 20px;
}

.accordionBtn {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    border: solid 1px rgb(0 0 0 / 13%);
    transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        border-radius 0.15s ease;
    outline: 0;
    background-color: white;
    overflow-anchor: none;
    cursor: pointer;
}

.accordionBtnAct {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    border: 0;
    transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        border-radius 0.15s ease;
    outline: 0;
    overflow-anchor: none;
    color: #0c63e4;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    cursor: pointer;
}

.accordionBtnAct:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 2px rgb(13 110 253 / 25%);
    outline: 0;
}

.accordionBtn::after {
    width: 16px;
    height: 16px;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 16px;
    transition: transform 0.2s ease-in-out;
}

.accordionBtnAct::after {
    width: 16px;
    height: 16px;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 16px;
    transform: rotate(-180deg);
    transition: 0.2s ease-in-out;
}

.table {
    width: 800px;
    margin-left: 40px;
    margin-bottom: 10px;
    border-collapse: collapse;
}

.thRow {
    color: #868E96;
    border-bottom: solid 1px #F1F1F5;
    font-weight: 400;
    height: 40px;
}

.thOver {
    justify-content: center;
}

.overHead {
    background-color: #F1F1F5;
    width: 90%;
    border-radius: 4px;
    color: #868E96;
    margin: 0 auto;
}

.th_id {
    width: 96px;
    text-align: center;
}

.th_name {
    width: 150px;
    text-align: center;
}

.th_date {
    width: 100px;
    text-align: start;
    padding-left: 40px;
}

.th_link {
    width: 40px;
    text-align: center;
    cursor: pointer;
}

.table td {
    color: #131523;
    border-bottom: solid 1px #F1F1F5;
    font-size: 1.2rem;
    height: 40px;
}

.tdId {
    width: 96px;
    text-align: center;
}

.tdName {
    width: 200px;
    text-align: start;
    padding-left: 25px;
}

.tdDate {
    width: 100px;
    text-align: start;
}

.tdLink {
    width: 40px;
    text-align: center;
    cursor: pointer;
}

.icon {
    color: #007BFF;
}

.iconBtn {
    color: #007BFF;
    background-color: white;
    border: solid 1px #F1F1F5;
    border-radius: 5px;
    padding: 5px;
}

.iconBtn:hover {
    cursor: pointer;
    background-color: #007bff80;
}

.iconBtn:focus {
    outline: 2px #007bff80 solid;
}

.iconBtnRed {
    color: #F18079;
    background-color: white;
    border: solid 1px #F1F1F5;
    border-radius: 5px;
    padding: 5px;
}

.iconBtnRed:hover {
    cursor: pointer;
    background-color: #F1807980;
}

.iconBtnRed:focus {
    outline: 2px #F1807980 solid;
}

.noMapBtn {
    text-align: start;
    margin: 5px 0 0 50px;
    font-size: 1.4rem;
    ;
}

.rowOver {
    background-color: #F1F1F5;
}
