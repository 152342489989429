.main {
    display: flex;
    width: 402px;
    height: 31px;
}

.box {
    display: inline-block;
    width: 32px;
    height: 19px;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
}

.space {
    width: 5px;
}