.wrap {}

.underPane {
    width: 900px;
    height: 700px;
    background-color: white;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
    text-align: start;
}

.listTitle {
    text-align: start;
    font-size: 2.0rem;
    font-weight: 700;
    margin-left: 26px;
    padding-top: 9px;
}

.container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.children {
    z-index: 990;
}
