.main {
}

.title {
    text-align: start;
    font-size: 1.2rem;
    color: #7E84A3;
    width: 45px;
    padding: 10px 0 0 40px;
}

.body {
    padding: 5px 10px 5px 50px;
}

.body table {
    /*table-layout: fixed;*/
}

.body tr {
    height: 24px;
}

.order {
    text-align: start;
    font-size: 1.2rem;
    color: #7E84A3;
    width: 12px;
    padding: 0 2px;
}

.name {
    font-size: 1.2rem;
    max-width: 150px;
    width: 150px;
    padding: 0 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: start;
}

.timmeValue {
    font-size: 1.2rem;
    width: 50px;
    padding: 0 10px;
}

.ratioValue {
    font-size: 1.2rem;
    width: 35px;
    padding: 0 10px;
    text-align: end;
}