.footer {
    display: flex;
    background-color: black;
    padding: 15px 15px;
    font-size: 1.5rem;
}

.footerLeft {
    flex-basis: 0;
    flex-grow: 1;
}

.footerCenter {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.footerRight {
    flex-basis: 0;
    flex-grow: 1;
    text-align: right;
}

.footerCenterText {
    color: white;
}

.footerRightText {
    color: white;
}

.footerRightText a:link {
    text-decoration: none;
    font-weight: 500;
    color: white;
}
.footerRightText a:hover {
    text-decoration: none;
    font-weight: 500;
    color: white;
}
.footerRightText a:visited {
    text-decoration: none;
    font-weight: 500;
    color: white;
}
.footerRightText a:active {
    text-decoration: none;
    font-weight: 500;
    color: white;
}
