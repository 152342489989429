.main {
    display: flex;
}

.moving {}

.zooming {
    padding: 32px 5px 0 40px;
}

.iconBtn {
    color: #007BFF;
    background-color: rgba(178, 217, 253, 0.5);
    border: solid 1px #F1F1F5;
    border-radius: 5px;
    padding: 3px;
}

.iconBtn:hover {
    cursor: pointer;
    background-color: #007bff80;
}

.iconBtn:focus {
    outline: 2px #007bff80 solid;
}
