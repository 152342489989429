.main {
    background-color: #F4F7FC;
}

.pane {
    background-color: white;
    min-width: 1130px;
    border-radius: 6px;
    box-shadow: 0 1px 4px #15223214;
    padding-bottom: 10px;
}

.title {
    text-align: start;
    padding-top: 20px;
    margin-left: 26px;
    font-size: 2.0rem;
    font-weight: 700;
    color: #212529;
}

.control {
    display: flex;
    margin-top: 17px;
}

.cancel {
    margin-left: 26px;
}

.update {
    margin-left: 13px;
}

.layout {}

.frame {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 60%;
    margin-top: 13px;
    margin-left: 26px;
}

.box {
    width: 215px;
    height: 203px;
    margin: 5px;
    text-align: center;
    box-shadow: 0 3px 6px #00000029;
    background-color: white;
}

.error {
    background-color: rgb(250, 219, 219);
    border: solid 1px red;
    border-radius: 6px;
    text-align: center;
    justify-content: center;
    width: 300px;
    height: 25px;
    color: red;
    font-size: 1.8rem;
    font-weight: 600;
    left: 250px;
    padding: 8px;
    margin: 0 0 10px 10px;
}
