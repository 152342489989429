.main {
    padding: 10px 0;
}

.mainLarge {
    padding: 15px 10px;
}

.title {
    text-align: start;
    font-size: 1.2rem;
    color: #7E84A3;
    display: flex;
    width: 45px;
    padding: 0 0 10px 40px;
}

.titleLarge {
    text-align: start;
    font-size: 1.6rem;
    color: #7E84A3;
    display: flex;
    width: 60px;
    padding: 0 0 10px 80px;
}

.body {
    display: flex;
}

.canvas {}

.spiner {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    padding: 20px;
}
