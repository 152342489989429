.main {
    display: flex;
}

.block {
    margin: 2px 0 5px 0;
}

.space {
    width: 20px;
}

.stay1 {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: rgb(0, 123, 255);
    border-radius: 50%;
    margin: 0px 5px 0px 0px;
}

.stay2 {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: rgb(41, 203, 151);
    border-radius: 50%;
    margin: 0px 5px 0px 0px;
}

.text {
    font-size: 1.2rem;
    margin: 10px 0 5px 0;
    padding: 0px 0 15px 0;
    vertical-align: top;
    color: #5A607F;
}

.pass1 {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: rgb(103, 176, 255);
    border-radius: 50%;
    margin: 0px 5px 0 0px;
}

.pass2 {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: rgb(126, 224, 192);
    border-radius: 50%;
    margin: 0px 5px 0 0px;
}