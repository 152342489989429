.main {
    line-height: 30px;
    letter-spacing: 0;
    text-align: center;
    font-size: 13px;
    position: relative;
    /*margin: auto;*/
    width: 75px;
    background-color: white;
}

.main input[type="checkbox"] {
    display: none;
}

.main label {
    display: block;
    box-sizing: border-box;
    height: 30px;
    border: 1px solid #868E96;
    border-radius: 15px;
}

.main input[type="checkbox"]:checked+label {
    border-color: #007bff;
    ;
}

.main label span:after {
    content: "OFF";
    padding: 0 0 0 18px;
    color: #868E96;
}

.main input[type="checkbox"]:checked+label span:after {
    content: "ON";
    padding: 0 18px 0 0;
    color: #007bff;
}

.iconImg {
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: #868E96;
    top: 2px;
    left: 2px;
    border-radius: 13px;
    transition: 0.2s;
}

.main input[type="checkbox"]:checked~.iconImg {
    transform: translateX(45px);
    background-color: #007bff;
}