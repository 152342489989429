.panel {
    width: 1100px;
    background-color: white;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
}

.title {
    text-align: start;
    font-size: 2.0rem;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 26px;
}

.main {
    margin: 0 40px 10px 50px;
}

.ctrlRow {
    display: flex;
    padding: 0 0 5px 0;
}

.startCol {
    width: 700px;
}

.checkCol {
    
}

.endCol {

}

.table {
    width: 880px;
    margin-left: 10px;
    margin-bottom: 10px;
    border-collapse: collapse;
}

.table th {
    color: #868E96;
    border-bottom: solid 1px #F1F1F5;
    font-weight: 400;
    height: 40px;
}

.thId {
    width: 50px;
    text-align: center;
}

.thName {
    width: 150px;
    text-align: center;
}

.thMail {
    width: 200px;
    text-align: center;
}


.thType {
    width: 160px;
    text-align: center;
}

.thDate {
    width: 130px;
    text-align: start;
    padding-left: 40px;
}

.thLink {
    width: 40px;
    text-align: center;
    cursor: pointer;
}

.table td {
    color: #131523;
    border-bottom: solid 1px #F1F1F5;
    font-size: 1.2rem;
    height: 40px;
}

.tdId {
    width: 50px;
    text-align: center;
}

.tdName {
    width: 150px;
    text-align: start;
    padding-left: 25px;
}

.tdMail {
    width: 200px;
    text-align: start;
    padding-left: 25px;
}

.tdType {
    width: 160px;
    text-align: start;
    padding-left: 25px;
}

.tdDate {
    width: 130px;
    text-align: start;
}

.tdLink {
    width: 40px;
    text-align: center;
}

.icon {
    color: #007BFF;
    cursor: pointer;
}

.iconDisabled {
    color: #868E9680;
    cursor: not-allowed;
}

.trOver {
    background-color: #F1F1F5;
    cursor: pointer;
}