.normal {
    background-color: #007bff;
    border: solid 1px #007bff;
    border-radius: 5px;
    color: white;
    font-size: 1.6rem;
    padding: 0.9rem 1.3rem;
    letter-spacing: 0.1rem;
}
.normal:hover {
    cursor: pointer;
    background-color: #0058ff;
}
.normal:hover:disabled{
    cursor: not-allowed;
}
.normal:disabled {
    /*opacity: 0.65;*/
    background-color: #007bff9f;
    border: solid 1px #007bff9f;
    border-radius: 5px;
}
.normal:focus {
    /*outline: 1px #007bff80 solid;*/
    outline: none;
    border: solid 1px #007bff80;
}

.reverse {
    background-color: #6C757D;
    border: solid 1px #6C757D;
    border-radius: 5px;
    color: white;
    font-size: 1.6rem;
    padding: 0.9rem 1.3rem;
    letter-spacing: 0.1rem;
}
.reverse:hover {
    background-color: #61696f;
    cursor: pointer;
}
.reverse:disabled {
    /*opacity: 0.65;*/
    background-color: #6C757D9F;
    border: solid 1px #6C757D9F;
    border-radius: 5px;
}
.reverse:hover:disabled {
    cursor: not-allowed;
}
.reverse:focus {
    /*outline: 1px #6C757D80 solid;*/
    outline: none;
    border: solid 1px #6C757D80;
}

.primary {
    background-color: #0058ff;
    border: solid 1px #0058ff;
    border-radius: 4px;
    color: white;
    font-size: 1.3rem;
    font-weight: 700;
    padding: 0.9rem 0.9rem 0.8rem 0.9rem;
    letter-spacing: 0.1rem;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
    font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
}
.primary:hover {
    cursor: pointer;
    background-color: #0358E8;
}
.primary:focus {
    /*outline: 1px #0058ff80 solid;*/
    outline: none;
    border: solid 1px #0058ff80;
}

.secondary {
    background-color: white;
    border: solid 1px #d7dbec;
    border-radius: 4px;
    color: #131523;
    font-size: 1.3rem;
    font-weight: 500;
    padding: 0.9rem 0.9rem 0.8rem 0.9rem;
    letter-spacing: 0.1rem;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
    font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
}
.secondary:hover {
    cursor: pointer;
    background-color: rgb(237, 243, 252);
}
.secondary:focus {
    /*outline: 2px #0058ff80 solid;*/
    outline: none;
    border: solid 1px #0058ff80;
}

.danger {
    background-color: #DC3545;
    border: solid 1px #DC3545;
    border-radius: 4px;
    color: white;
    font-size: 1.6rem;
    font-weight: 500;
    padding: 0.9rem 0.9rem 0.8rem 0.9rem;
    letter-spacing: 0.1rem;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
    font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
}
.danger:hover {
    background-color: #e93144;
    cursor: pointer;
}
.danger:focus {
    /*outline: 1px #DC354580 solid;*/
    outline: none;
    border: solid 1px #DC354580;
}
.danger:disabled {
    background-color: #DC35459F;
    border: solid 1px #DC35459F;
}
.danger:hover:disabled {
    cursor: not-allowed;
}

.dangerMini {
    background-color: #DC3545;
    border: solid 1px #DC3545;
    border-radius: 4px;
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0.9rem 0.9rem 0.8rem 0.9rem;
    letter-spacing: 0.1rem;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
    font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
}
.dangerMini:hover {
    background-color: #e93144;
    cursor: pointer;
}
.dangerMini:focus {
    /*outline: 1px #DC354580 solid;*/
    outline: none;
    border: solid 1px #DC354580;
}

.outline {
    background-color: white;
    border: solid 1px #007bff;
    border-radius: 6px;
    color: #007bff;
    font-size: 1.6rem;
    font-weight: 400;
    padding: 0.4rem 1.4rem;
    letter-spacing: 0.1rem;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
    font-family: 'Helvetica Neue','Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
}
.outline:hover {
    cursor: pointer;
    background-color: #f8f8f8;
}
.outline:focus {
    /*outline: 1px #007bff80 solid;*/
    outline: none;
    border: solid 1px #007bff80;
}
.outline:hover:disabled {
    cursor: not-allowed;
}
.outline:disabled {
    /*opacity: 0.65;*/
    color: #007bff7f;
    border: solid 1px #007bff7f;
    border-radius: 5px;
}

.outwide {
    background-color: white;
    border: solid 1px #007bff;
    border-radius: 6px;
    color: #007bff;
    font-size: 1.6rem;
    font-weight: 400;
    padding: 0.4rem 2.5rem;
    margin: 0;
    letter-spacing: 0.1rem;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
    font-family: 'Helvetica Neue', 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
}
.outwide:hover {
    cursor: pointer;
    background-color: #f8f8f8;
}
.outwide:focus {
    /*outline: 1px #007bff80 solid;*/
    outline: none;
    border: solid 1px #007bff80;
}

.outmini {
    background-color: white;
    border: solid 1px #007bff;
    border-radius: 6px;
    color: #007bff;
    font-size: 1.3rem;
    font-weight: 400;
    padding: 0.3rem 1.0rem;
    margin: 3px;
    letter-spacing: 0.1rem;
    text-align: center;
    vertical-align: middle;
    line-height: 1.1;
}
.outmini:hover {
    background-color: #f8f8f8;
    cursor: pointer;
}
.outmini:focus {
    /*outline: 1px #007bff80 solid;*/
    outline: none;
    border: solid 1px #007bff80;
}

.muliAct {
    background-color: #0058ff;
    border: solid 1px #0058ff;
    border-radius: 4px;
    color: white;
    font-family: 'Mulish', sans-serif;
    font-size: 1.3rem;
    font-weight: 700;
    padding: 0.5rem 1.2rem;
    letter-spacing: 0.1rem;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
}
.muliAct:hover {
    background-color: #0358E8;
    cursor: pointer;
}
.muliAct:focus {
    /*outline: 1px #0058ff80 solid;*/
    outline: none;
    border: solid 1px #0058ff80;
}

.muliInactive {
    background-color: white;
    border: solid 1px #d7dbec;
    border-radius: 4px;
    color: #131523;
    font-family: 'Mulish', sans-serif;
    font-size: 1.3rem;
    font-weight: 600;
    padding: 0.5rem 1.2rem;
    letter-spacing: 0.1rem;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
}
.muliInactive:hover {
    background-color: rgb(237, 243, 252);
    cursor: pointer;
}
.muliInactive:focus {
    /*outline: 2px #0058ff80 solid;*/
    outline: none;
    border: solid 1px #0058ff80;
}

.mmAct {
    background-color: #0058ff;
    border: solid 1px #0058ff;
    border-radius: 4px;
    color: white;
    font-family: 'Mulish', sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    padding: 9px 11px;
    vertical-align: middle;
    line-height: 1.3;
}
.mmAct:hover {
    background-color: #0358E8;
    cursor: pointer;
}
.mmAct:focus {
    /*outline: 1px #0058ff80 solid;*/
    outline: none;
}

.mmInact {
    background-color: white;
    border: solid 1px #d7dbec;
    border-radius: 4px;
    color: #131523;
    font-family: 'Mulish', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 9px 11px;
    vertical-align: middle;
    line-height: 1.3;
}
.mmInact:hover {
    background-color: rgb(237, 243, 252);
    cursor: pointer;
}
.mmInact:focus {
    /*outline: 1px #0058ff80 solid;*/
    outline: none;
    border: solid 1px #0058ff80;
}