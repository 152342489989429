.legendS {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 0;
    flex-grow: 1;
    margin-top: 25px;
    margin-left: 32px;
}

.legendS1 {
    margin: 2px 0 5px 0;
}

.stay1 {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: rgb(0, 123, 255);
    border-radius: 50%;
    margin: 0px 5px 0px 0px;
}

.text {
    font-size: 1.2rem;
    margin: 10px 0 5px 0;
    padding: 0px 0 15px 0;
    vertical-align: top;
    color: #5A607F;
}

.spaceS {
    width: 20px;
}

.legendS2 {
    margin: 2px 0 5px 0;
}

.pass1 {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: rgb(103, 176, 255);
    border-radius: 50%;
    margin: 0px 5px 0 0px;
}

.legendW {
    display: flex;
    flex-wrap: wrap;
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    margin: 10px 0 13px 45px;
    color: #5A607F;
}

.stay2 {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: rgb(41, 203, 151);
    border-radius: 50%;
    margin: 0px 5px 0 0px;
}

.pass2 {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: rgb(126, 224, 192);
    border-radius: 50%;
    margin: 0px 5px 0 0px;
}

.title {
    padding-right: 20px;
}

.time {
    padding-right: 20px;
    text-align: start;
}
