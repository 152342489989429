.wrapIcon {
    width: 28px;
    height: 28px;
    margin-top: 5px;
    color: #FFF;
    background-color: #007BFF;
    border: solid 1px #007BFF;
    border-radius: 50%;
    padding: 3px 0 0 3px;
}

.doubleCircle {
    width: 40px;
    height: 40px;
    position: relative;
    border: solid 2px #007BFF;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: #FFF;
}

.doubleCircle::before {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    top: 3.5px;
    left: 3.5px;
    background-color: #007BFF;
    border: none;
    border-radius: 50%;
    box-sizing: border-box;
}

.inner {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 6px;
    left: 8px;
    color: #FFF;
    font-size: 1.5rem;
    font-weight: 500;
}

.normalCircle {
    position: relative;
    width: 30px;
    height: 30px;
    margin-top: 5px;
    background-color: #4FA4FF;
    border: solid 1px #4FA4FF;
    border-radius: 50%;
    box-sizing: border-box;
}

.misty {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 3px;
    left: 4px;
    color: #FFF;
    font-size: 1.5rem;
    font-weight: 500;
}

