.panel {
    width: 1100px;
    height: 681px;
    background-color: white;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
}

.head {
    display: flex;
}

.title {
    flex-basis: 0;
    flex-grow: 1;
    text-align: start;
    font-size: 2.0rem;
    font-weight: 700;
    margin-top: 30px;
    margin-left: 36px;
}

.close {
    padding: 10px;
}

.body {
    display: flex;
    text-align: start;
}

.leftside {
    margin-left: 60px;
}

.lefttop {
    margin-top: 9px;
}

.list {
    margin: 5px 0 0 85px;
    height: 115px;
    text-align: start;
    flex-wrap: nowrap;
    flex-basis: 0;
}

.table {
    width: 440px;
    border-collapse: collapse;
}

.lsHead {
    width: 94px;
    border-bottom: solid 1px #F1F1F5;
    color: #7E84A3;
    font-size: 1.2rem;
    height: 25px;
}

.lsData {
    width: 421px;
    border-bottom: solid 1px #F1F1F5;
    color: #131523;
    font-size: 1.4rem;
}

.leftbtm {
    margin-top: 21px;

}

.section {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 28px;
}

.legend {
    margin-top: 20px;
}

.unregistered {
    margin: 19px 0 9px 0px;
}

.yellow {
    color: white;
    font: 0.8rem "Poppins";
    font-weight: 600;
    background: rgb(255, 199, 0);
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 5px;
    padding: 2px;
}

.text {
    font-size: 1.2rem;
    margin: 10px 0 5px 0;
    padding: 0px 0 15px 0;
    vertical-align: middle;
    color: #5A607F;
}

.map {
    width: 620px;
    margin-left: 40px;
}

.canvasWrap {
    position: relative;
    padding: 0;
    margin: 0;
    box-sizing: content-box;
    width: 620px;
}

.canvasWrap::before {
    content: "";
    display: block;
    padding-top: 0%;
}

.canvas {
    z-index: 1;
    position: absolute;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
}

.canvasBorder {
    border: solid 1px black;
}

.edtCanvas {
    z-index: 5;
    position: absolute;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    border: solid 1px #F1F1F5;
    border-radius: 5xp;
}

.aniCanvas {
    z-index: 10;
    position: absolute;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    border: solid 1px #F1F1F5;
    border-radius: 5xp;
}

.rightside {
    margin-top: 55px;
    margin-left: 10px;
    width: 300px;
}

.rightTop {
    width: 250px;
    display: flex;
    margin: 20px 0 40px 15px;
    height: 45px;
}

.rightMid {
    width: 295px;
    padding: 5px;
    margin: 5px 0 10px 5px;
    border-bottom: solid 1px #F1F1F5;
}

.startDate {
    display: flex;
}

.startLabel {
    color: #7E84A3;
    font-size: 1.2rem;
    padding: 7px 12px 1px 0px;
}

.startBorder {
    border: solid 1px #F1F1F5;
    border-radius: 4px;
    width: 215px;
}

.rightBottom {

}

.buttons {
    flex-wrap: nowrap;
    flex-grow: 1;
    text-align: start;
    
}

.btnRow1st {
    display: flex;
    height: 43px;
    justify-content: center;
}

.btnRow2nd {
    margin: 40px 0 10px 0px;
}

.space {
    width: 24px;
}

.spaceW {
    width: 110px;
}

.zoomBtn {
    z-index: 12;
    position: absolute;
    top: 0;
    right: 265px;
}


.memo {
    position: absolute;
    top: 720px;
}

.memoText {
    color: #7E84A3;
    font-size: 1.2rem;
}

.spc {
    padding: 10px 15px;
}


.lineSelect {
    padding: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: scroll;
    appearance: none;
    -webkit-appearance: none;
    border: 1px solid #d7dbec;
    border-radius: 4px;
    color: #868e96;
    width: 275px;
    font-size: 1.2rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    margin-left: 10px;
    outline: none;
}

.lineSelect:hover {
    outline: 2px #007bff solid;
}

.lineSelect option {
    padding: 2px 15px;
}

.msgSelect {
    font-size: 1.2rem;
    color: #868E96;
    padding: 2px 0 0 25px;
    height: 20px;
}

.dialogPane {
    width: 600px;
    height: 180px;
    border-radius: 6px;
}

.dialogHead {
    font-size: 1.8rem;
    padding: 34px 0 22px 57px;
}

.dialogBody {
    padding: 0 0 22px 57px;
}

.confirm {
    color: #868E96;
    font-size: 1.6rem;
}

.dialogBottom {
    text-align: end;
    padding: 0 41px 0 0px;
}