.main {
    width: 930px;
    height: 480px;
    background-color: white;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
    text-align: start;
}

.head {
    display: flex;
}

.title {
    flex-basis: 0;
    flex-grow: 1;
    text-align: start;
    font-size: 2.0rem;
    font-weight: 700;
    margin: 30px 0 0 36px;
}

.close {
    padding: 10px;
}

.panel {
    margin: 10px 0 0 60px;
    height: 400px;
    display: flex;
}

.left {
    padding: 30px 0 0 0;
}

.table {
    width: 500px;
    border-collapse: collapse;
}

.dtTitle {
    border-bottom: solid 1px #F1F1F5;
    color: #7E84A3;
    font-size: 1.2rem;
    height: 32px;
    width: 120px;
    padding-left: 8px;
}

.dtData {
    border-bottom: solid 1px #F1F1F5;
    color: #131523;
    font-size: 1.4rem;
    height: 32px;
    padding-left: 18px;
}

.dtInput {
    border-bottom: solid 1px #F1F1F5;
    color: #131523;
    font-size: 1.4rem;
    height: 32px;
    padding-left: 18px;
}

.timezone {
    appearance: none;
    -webkit-appearance: none;
    background-image: url(./img/expand_more_FILL0_wght400_GRAD0_opsz24.svg);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    border: 1px solid #d7dbec;
    border-radius: 4px;
    color: #868e96;
    width: 212px;
    height: 30px;
    font-size: 1.3rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    padding-left: 15px;
}

.timezone:focus {
    outline: 2px #007bff solid;
}

.startDate {
    width: 210px;
    border: solid 1px #d7dbec;
    border-radius: 4px;
    padding: 0;
}

.right {
    width: 300px;
    margin-left: 20px;
}

.rightTop {
    display: flex;
    margin: 30px 0 0 45px;
    text-wrap: nowrap;
}

.rightMid {
    height: 220px;
}

.rightBtm {
    margin-left: 120px;
}

.btnSpace {
    width: 30px;
}

.dtInput input {
    width: 300px;
    height: 25px;
    font-size: 1.3rem;
    padding: 0 12px;
    font-weight: 400;
    color: #868D96;
    border: 1px solid #ced4da;
    border-radius: 6px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    letter-spacing: 0.1rem;
}

.dtInput input:focus {
    outline: 2px #007bff solid;
}

.dialogPane {
    width: 567px;
    height: 208px;
    border-radius: 6px;

}

.dialogHead {
    font-size: 1.8rem;
    padding: 34px 0 22px 57px;
}

.dialogBody {
    padding: 0 0 22px 57px;
}

.confirm {
    color: #868E96;
    font-size: 1.6rem;
}

.dialogBottom {
    text-align: end;
    padding: 0 41px 0 0px;
}

.spc {
    padding: 10px 15px;
}

.table td p {
    margin: 3px 0 4px 10px;
}

.errorMessage {
    font-size: 1.0rem;
    color: red;
}
