.main {
    background-color: #F4F7FC;
    width: 100%;
    height: 100vh;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
}

.pane {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 1px 4px #15223214;
    width: 500px;
    height: 300px;
}

.title {
    margin-top: 19px;
    padding-top: 10px;
    margin-left: 26px;
    font-size: 2.0rem;
    font-weight: 700;
    color: #212529;
}

.body {
    margin: 30px;
    font-size: 1.2rem;
    font-weight: 400;
    color: #212529;
}

.text {
    text-align: center;
}

.button {
    padding: 50px;
}

.error {
    position: relative;
    background-color: rgb(250, 219, 219);
    border: solid 1px red;
    border-radius: 6px;
    text-align: center;
    justify-content: center;
    width: 300px;
    height: 50px;
    color: red;
    font-size: 1.3rem;
    font-weight: 600;
    left: 50px;
    padding: 10px;
    margin: 20px;
}

.foot {
    position: sticky;
    top: 100vh;
    width: 100%;
}