.content {
    text-align: start;
    padding-bottom: 9px;
}

.mainContents {
    display: flex;
}

.mapPane {
    width: 740px;
    margin: 30px 10px 20px 20px;
    padding: 5px;
    background-color: white;
    box-shadow: inset 0px 0px 6px #00000029;
}

.trailErrs {
    position: relative;
    top: -25px;
    left: 50px;
    color: red;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 5px;
}

.canvasWrap {
    position: relative;
    padding: 0;
    margin: 0 0 0px 0;
    box-sizing: content-box;
    width: 740px;
    /*height: 550x;*/
}

.canvasWrap::before {
    content: "";
    display: block;
    padding-top: 0%;
}

.canvas {
    z-index: 9;
    position: absolute;
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
}

.bgImgCanvas {
    z-index: 1;
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
}

.aniCanvas {
    z-index: 10;
    position: absolute;
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
}

.progress {
    position: relative;
    top: -30px;
}

.subList {
    
}

.detail {

}

.ctrlPane {
    display: flex;
    margin-bottom: 10px;
}

.switch {
    display: flex;
    margin: 0 auto;
}

.listPaneSng {
    background-color: white;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-left: 12px;
    margin-bottom: 10px;
}

.listPaneCmp {
    padding-top: 5px;
    padding-bottom: 10px;
    margin-left: 12px;
    margin-bottom: 10px;
}

.zoomBtn {
    z-index: 12;
    position: absolute;
    top: 0;
    right: 333px;
}
