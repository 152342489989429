.outer {
    border: solid 1px #d7dbec;
    border-radius: 4px;
    width: 180px;
    /*height: 150px;*/
}

.header {

}

.body {
    margin-left: 40px;
    margin-right: 0px;
    height: 160px;
}

.footer {
    border-top: 1px solid #d7dbec;
    text-align: center;
}

.numbers {
    display: inline-block;
    font-size: 1.7rem;
    color:#868e96;
    font-weight: 300;
    width: 100px;
    text-align: center;
    border-left: solid 1px #d7dbec;
    border-right: solid 1px #d7dbec;
}

.smallest {
    transform: scale(1, 0.5);
    /*background-color: #d7dbec;*/
    height: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-width: 0;
}

.smaller {
    transform: scale(1, 0.86);
    height: 23px;
    /*background-color:aquamarine;*/
    padding-top: 8px;
    padding-bottom: 8px;
    border-width: 0;
}

.target {
    background-color:#007bff;
    color: white;
    font-weight: 500;
    height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-width: 0;
}

.bigger {
    transform: scale(1, 0.86);
    height: 23px;
    /*background-color:aquamarine;*/
    padding-top: 8px;
    padding-bottom: 8px;
    border-width: 0;
}

.biggest {
    transform: scale(1, 0.5);
    /*background-color: #d7dbec;*/
    height: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-width: 0;
}

.gear {
    display: inline-block;
    font-size: 1.7rem;
    width: 20px;
    text-align: center;
    color: #868e96;
}

.dec2 {

}

.dec {
    height: 34px;
    padding: auto;
    cursor: pointer;
}

.non {
    height: 40px;
    padding: auto;
}

.inc {
    height: 34px;
    padding: auto;
    cursor: pointer;
}

.inc2 {

}

