.startBtn {
    text-align: center;
    margin: 0 0 16px 0;
    width: 1120px;
}

.updateBtn {
    margin: 0 0 16px 0;
    width: 1120px;
}

.centering {
    margin: auto;
}

.space {
    width: 30px;
    display: inline-flex;
}

.dummy {
    display: inline-flex;
    width: 400px;
}