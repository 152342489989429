
.panel {
    width: 36px;
    height: 400px;
    text-align: center;
}

.withBorder {
    border: solid 1px var(--atiko-gray-dde);
    border-radius: 10px;
    background-color: inherit;
}

.panelS {
    width: 24px;
    height: 300px;
    text-align: center;
}

.withBorderS {
    border: solid 1px var(--atiko-gray-dde);
    border-radius: 6px;
    background-color: inherit;
}

.panelXS {
    width: 24px;
    height: 150px;
    text-align: center;
}

.withBorderXS {
    border: solid 1px var(--atiko-gray-dde);
    border-radius: 6px;
    background-color: inherit;
}


.iconTop {
    color: var(--atiko-gray-677);
    cursor: pointer;
}

.iconTop svg {
    position: relative;
    top: 5px;
}

.iconTopS {
    color: var(--atiko-gray-677);
    cursor: pointer;
}

.iconTopS svg {
    position: relative;
    top: -1px;
}

.iconTopXS {
    color: var(--atiko-gray-677);
    cursor: pointer;
}

.iconTopXS svg {
    position: relative;
    top: -4px;
}

.main {
    padding: 3px auto;
}

.mainS {
    padding: 2px auto;
}

.iconBottom {
    color: var(--atiko-gray-677);
    cursor: pointer;
}

.iconBottom svg {
    position: relative;
    top: 305px;
}

.iconBottomS {
    color: var(--atiko-gray-677);
    cursor: pointer;
}

.iconBottomS svg {
    position: relative;
    top: 222px;
}

.iconBottomXS {
    color: var(--atiko-gray-677);
    cursor: pointer;
}

.iconBottomXS svg {
    position: relative;
    top: 73px;
}

.range {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: none;
    height: 14px;
    width: 320px;
    background: #8ACDFF;
    border-radius: 10px;
    border: solid 3px #DFF1FF;
    margin: 7px 0 5px 0;
}

.upMin {
    transform: rotate(90deg) translateY(-28px) translateX(8px);
    transform-origin: left top;
}

.downMin {
    transform: rotate(-90deg) translateY(9px) translateX(-333px);
    transform-origin: left top;
}

.range::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #53ADFF;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
}

.range::-moz-range-thumb {
    background: #53ADFF;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: 0px 3px 6x 0px rgba(0, 0, 0, 0.15);
    border: none;
}

.range::-moz-focus-outer {
    border: 0;
}

.range:active::-webkit-slider-thumb {
    box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.3);
}


.rangeS {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: none;
    height: 5px;
    width: 240px;
    background: var(--bs-gray-400);
    border-radius: 4px;
    border: solid 1px var(--bs-gray-500);
    margin: 5px 0 3px 0;
}

.upMinS {
    transform: rotate(90deg) translateY(-15px) translateX(-5px);
    transform-origin: left top;
}

.downMinS {
    transform: rotate(-90deg) translateY(9px) translateX(-239px);
    transform-origin: left top;
}

.rangeS::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #FFF;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 1px var(--atiko-gray-677);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.rangeS::-moz-range-thumb {
    background: #FFF;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 1px var(--atiko-gray-677);
    box-shadow: 0px 2px 4x 0px rgba(0, 0, 0, 0.15);
}

.rangeS::-moz-focus-outer {
    border: 0;
}

.rangeS:active::-webkit-slider-thumb {
    box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.3);
}

.rangeXS {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: none;
    height: 5px;
    width: 100px;
    background: var(--bs-gray-400);
    border-radius: 4px;
    border: solid 1px var(--bs-gray-500);
    margin: 5px 0 3px 0;
}

.upMinXS {
    transform: rotate(90deg) translateY(-15px) translateX(-9px);
    transform-origin: left top;
}

.downMinXS {
    transform: rotate(-90deg) translateY(9px) translateX(-93px);
    transform-origin: left top;
}

.rangeXS::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #FFF;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 1px var(--atiko-gray-677);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.rangeXS::-moz-range-thumb {
    background: #FFF;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 1px var(--atiko-gray-677);
    box-shadow: 0px 2px 4x 0px rgba(0, 0, 0, 0.15);
}

.rangeXS::-moz-focus-outer {
    border: 0;
}

.rangeXS:active::-webkit-slider-thumb {
    box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.3);
}