html {
  font-size: 62.5%;
  /* 16px * 62.5% = 10px = 1.0rem */
}

body {
  margin: 0;
  font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  /* 14px */
  color: #212529;
  background-color: rgb(244, 247, 252);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

span.react-datepicker__aria-live {
  display: none;
}

:root {
  --bs-blue: #0D6EFD;
  --bs-indigo: #6610F2;
  --bs-purple: #6F42C1;
  --bs-pink: #D63384;
  --bs-red: #DC3545;
  --bs-orange: #FD7E14;
  --bs-yellow: #FFC107;
  --bs-green: #198754;
  --bs-teal: #20C997;
  --bs-cyan: #0DCAF0;
  --bs-black: #000;
  --bs-white: #FFF;
  --bs-gray: #6C757D;
  --bs-gray-dark: #343A40;
  --bs-gray-100: #F8F9FA;
  --bs-gray-200: #E9ECEF;
  --bs-gray-300: #DEE2E6;
  --bs-gray-400: #CED4DA;
  --bs-gray-500: #ADB5BD;
  --bs-gray-600: #6C757D;
  --bs-gray-700: #495057;
  --bs-gray-800: #343A40;
  --bs-gray-900: #212529;
  --bs-primary: #0D6EFD;
  --bs-secondary: #6C757D;
  --bs-success: #198754;
  --bs-info: #0DCAF0;
  --bs-warning: #FFC107;
  --bs-danger: #DC3545;
  --bs-light: #F8F9FA;
  --bs-dark: #212529;
  --atiko-font-noto-sans: 'Noto Sans JP',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  --atiko-font-poppins: 'Poppins',
    'Noto Sans JP',
    'Helvetica Neue',
    sans-serif;
  --atiko-font-helvetica-neue: 'Helvetica Neue',
    'Noto Sans JP',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    sans-serif;
  --atiko-font-mulish: 'Mulish',
    sans-serif;
  --atiko-blue: #007BFF;
  --atiko-blue-op65: #007BFF9F;
  --atiko-blue-op50: #007BFF80;
  --atiko-blue2: #0058ff;
  --atiko-blue2-op50: #0058ff80;
  --atiko-dull-blue: #4FA4FF;
  --atiko-gray-112: #131523;
  --atiko-gray-444: #444444;
  --atiko-gray-567: #5A607F;
  --atiko-gray-677: #6C757D;
  --atiko-gray-677-op65: #6C757D9F;
  --atiko-gray-677-op50: #6C757D80;
  --atiko-gray-dde: #D7DBEC;
  --atiko-gray-dde-op50: #D7DBEC80;
  --atiko-red: #DC3545;
  --atiko-red-op50: #DC354580;
  --atiko-shadow: #00000029;
}