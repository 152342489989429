.main {
    display: flex;
}

.leftItem {
    flex-basis: 0;
    flex-grow: 1;
}

.centerItem {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.rightItem {
    flex-basis: 0;
    flex-grow: 1;
    text-align: right;
}

.headerLarge {
    width: 100%;
    background-color: black;
    padding: 6px 0px 15px 20px;
}

.headerSmall {
    width: 311px;
    height: 28px;
    background-color: rgb(19, 21, 35);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 18px;
}

.logoLeftLarge {
    padding-top: 6px;
    height: 25px;
    max-width: 100%;
    float: left;
    vertical-align: middle;
}

.logoRightLarge {
    height: 40px;
    max-width: 100%;
    float: right;
    vertical-align: middle;
}

.logoLeftSmall {
    height: 26px;
    max-width: 100%;
    float: left;
    vertical-align: middle;
}

.logoRightSmall {
    height: 26px;
    max-width: 100%;
    float: right;
    vertical-align: middle;
}

.headerLargeMockOn {
    width: 100%;
    background-color: orange;
    padding: 6px 0px 15px 20px;
}

.headerSmallMockOn {
    width: 311px;
    height: 28px;
    background-color: orange;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 18px;
}

.mockMsg {
    font-size: 2.0rem;
    font-weight: 700;
    margin: 0 auto;
}

.mockMsgSmall {
    font-size: 1.2rem;
    font-weight: 400;
}

.logout {
    font-size: 1.6rem;
    font-weight: 400;
    float: right;
    color: white;
    padding: 8px 35px 0 0;
}

.logout:link {
    text-decoration: none;
}

.logout:visited {
    text-decoration: none;
}

.logout:hover {
    text-decoration: underline;
}