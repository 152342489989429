.spinner {
    display: flex;
    justify-content: center;
    padding-left: 7px;
}

.overlay {
    position: fixed;
    /* overlayの絶対位置を左上を起点にし幅と高さを100%にする */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(160, 160, 160, 0.1);
    /*backdrop-filter: blur(1px);*/
    /* 一番下のbody領域をぼやかす */
    z-index: 1040;
}

.waiting {
    position: absolute;
    top: 473px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 10px;
    border-radius: 50%;
    /* modalに影を作る */
    z-index: 1050;
}

.waiting4Man {
    position: absolute;
    top: 48%;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 10px;
    border-radius: 50%;
    /* modalに影を作る */
    z-index: 1050;
}
