.content {
    text-align: start;
    padding-bottom: 10px;
}

.head {
    display: flex;
    margin: 25px 25px 4px 50px;
}

.legend {
    display: flex;
    justify-content: flex-start;
}

.dummy {
    margin: 0 auto;
}

.switch {
    justify-content: end;
}

.block {
    margin: 2px 0 5px 0;
}

.space {
    width: 20px;
}

.yellow {
    color: white;
    font: 0.8rem "Poppins";
    font-weight: 600;
    background: rgb(255, 199, 0);
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 5px;
    padding: 2px;
}

.stay1 {
    display: inline-block;
    color: white;
    font-weight: 600;
    font: 0.8rem "Poppins";
    background: rgb(0, 123, 255);
    border-radius: 3px;
    margin: 0px 5px 0px 0px;
    padding: 2px;
}

.stay2 {
    display: inline-block;
    color: white;
    font-weight: 600;
    font: 0.8rem "Poppins";
    background: rgb(41, 203, 151);
    border-radius: 3px;
    margin: 0px 5px 0px 0px;
    padding: 2px;
}

.text {
    font-size: 1.2rem;
    margin: 10px 0 5px 0;
    padding: 0px 0 15px 0;
    vertical-align: middle;
    color: #5A607F;
}

.pass1 {
    display: inline-block;
    color: white;
    font-weight: 600;
    font: 0.8rem "Poppins";
    background: rgb(103, 176, 255);
    border-radius: 3px;
    margin: 0px 5px 0px 0px;
    padding: 2px;
}

.pass2 {
    display: inline-block;
    color: white;
    font-weight: 600;
    font: 0.8rem "Poppins";
    background: rgb(126, 224, 192);
    border-radius: 3px;
    margin: 0px 5px 0px 0px;
    padding: 2px;
}

.comment {
    margin: 0 0 0 50px;
    color: #5A607F;
    font-size: 1.2rem;
}

.body {
    margin-left: auto;
    margin-right: auto;
}

.canvasWrap {
    position: relative;
    padding: 5px;
    margin: 0 0 0px 50px;
    box-sizing: content-box;
    width: 740px;
    height: 550x;
}

.canvasWrap::before {
    content: "";
    display: block;
    padding-top: 0%;
}

.canvas {
    z-index: 1;
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
}

.aniCanvas {
    z-index: 10;
    position: absolute;
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    top: 5px;
    left: 5px;
}

.zoomBtn {
    z-index: 12;
    position: absolute;
    top: 0;
    right: 333px;
}
