.border {
    border: solid 1px #d7dbec;
    border-radius: 4px;
    color: #868e96;
    background-color: white;
    font-size: 1.2rem;
    font-weight: 500;
    /*line-height: 1.0;*/
    width: 98px;
    padding: 5px 5px 0px 0px;
    font-family: 'Noto Sans JP', sans-serif;
}

.border:hover {
    cursor: pointer;
    background-color: #DEE2E6;
}

.iconFilter {
    width: auto;
    height: 16px;
    margin: 0px 0px 1px 9px;
}

.label {
    position: relative;
    top: -2px;
    padding-left: 15px;
    vertical-align: top;
}

.label:hover {
    cursor: pointer;
    background-color: #DEE2E6;
}
