.sMain {
    font-family: "Helvetica Neue",
        Arial,
        "Hiragino Kaku Gothic ProN",
        "Hiragino Sans",
        Meiryo,
        sans-serif;
    font-size: 1.6rem;
}

.sCvr {
    border: solid 1px #00000033;
    border-radius: 4px;
    background-color: white;
    width: 1085px;
    height: 411px;
    margin-bottom: 30px;
}

.sAreaCvr {
    border: solid 1px #00000033;
    border-radius: 4px;
    background-color: white;
    width: 1085px;
    height: 764px;
    margin-bottom: 24px;
}

.wMain {
    font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
    font-size: 1.6rem;
}

.wPanel {
    border: solid 1px #00000033;
    border-radius: 4px;
    background-color: white;
}

.wCvr {
    background-color: white;
    width: 557px;
    margin-bottom: 45px;
}

.wAreaCvr {
    background-color: white;
    width: 557px;
}

.caption {
    font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 17px;
}

.rankPanel {
    width: 1012px;
}

.rankPanelW {
    width: 533px;
    padding: 0 10px;
}

.title {
    text-align: start;
    font-family: "Helvetica Neue",
            Arial,
            "Hiragino Kaku Gothic ProN",
            "Hiragino Sans",
            Meiryo,
            sans-serif;
    font-weight: 600;
    font-size: 1.6rem;
    padding: 22px 0 22px 22px;
}

.rankTitle {
    text-align: start;
    font-family: "Helvetica Neue",
            Arial,
            "Hiragino Kaku Gothic ProN",
            "Hiragino Sans",
            Meiryo,
            sans-serif;
    font-weight: 600;
    font-size: 1.6rem;
    padding: 22px 0 14px 15px;
}

.tablePane {
    padding: 4px 0 0 0;
    margin: 0 113px 0 98px;
}

.tablePaneW {
    padding: 4px 0 0 0;
    margin: 0 10px 0 10px;
}

.table {
    width: 880px;
    font-size: 1.4rem;
    border-collapse: collapse;
}

.tableW {
    width: 534px;
    font-size: 1.2rem;
    border-collapse: collapse;
}

.thS {
    font-weight: 600;
    font-size: 1.6rem;
    border-bottom: solid 1px #0000001F;
    height: 29px;
}

.thW_1 {
    font-weight: 600;
    font-size: 1.4rem;
    border-bottom: solid 1px #0000001F;
    height: 29px;
    width: 155px;
    color: #5A607F;
    text-align: start;
    padding-left: 15px;
}

.thW {
    font-weight: 600;
    font-size: 1.4rem;
    border-bottom: solid 1px #0000001F;
    height: 29px;
    color: #5A607F;
    width: 80px;
}

.thW_2 {
    font-weight: 600;
    font-size: 1.4rem;
    border-bottom: solid 1px #0000001F;
    height: 29px;
    width: 200px;
    color: #5A607F;
}

.tdLeft {
    font-weight: 400;
    border-bottom: solid 1px #0000001F;
    height: 29px;
    text-align: start;
    padding-left: 27px;
}

.tdLeftW {
    font-weight: 400;
    border-bottom: solid 1px #0000001F;
    height: 29px;
    text-align: start;
    padding-left: 13px;
}

.tdRight {
    font-weight: 400;
    border-bottom: solid 1px #0000001F;
    height: 29px;
    text-align: end;
    padding-right: 10px;
}

.tdRightW {
    font-weight: 400;
    border-bottom: solid 1px #0000001F;
    height: 29px;
    text-align: end;
    padding-right: 15px;
}

.tdGraph {
    font-weight: 400;
    border-bottom: solid 1px #0000001F;
    height: 29px;
    width: 390px;
}

.tdGraphW {
    font-weight: 400;
    border-bottom: solid 1px #0000001F;
    height: 29px;
    width: 200px;
}

.bold {
    font-weight: 600;
}

.regular {
    font-weight: 400;
}

.collapsePane {
    margin: 0 auto;
    padding-top: 8px;
    padding-bottom: 40px;
}

.collapsePane ul {
    padding-left: 0;
    margin: 0;
}

.collapsePane li {
    list-style: none;
}

.collMain {
    margin-left: 35px;
}

.button {
    display: flex;
    cursor: pointer;
    border-bottom: solid 1px #0000001F;
    padding: 13px 24px 15px 29px;
}

.btnTop {
    display: flex;
    cursor: pointer;
    border: solid 1px #DFDFDF;
    padding: 13px 24px 15px 26px;
    border-radius: 4px 4px 0 0;
}
.btnMid {
    display: flex;
    cursor: pointer;
    border: solid 1px #DFDFDF;
    padding: 13px 24px 15px 26px;
    border-radius: 0 0 0 0;
}
.btnBtm {
    display: flex;
    cursor: pointer;
    border: solid 1px #DFDFDF;
    padding: 13px 24px 15px 26px;
    border-radius: 0 0 4px 4px;
}
.btnName {
    text-align: start;
    flex-basis: 0;
    flex-grow: 1;
    color: #007AFF;
    font-size: 1.5rem;
    font-weight: 400;
}

.btnNameW {
    text-align: start;
    flex-basis: 0;
    flex-grow: 1;
    color: #212529;
    font-size: 1.2rem;
    font-weight: 400;
    font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
}

.btnArrow {
    text-align: end;
    flex-basis: 0;
    flex-grow: 1;
    color: #343A40;
}

.btnArrowBlue {
    text-align: end;
    flex-basis: 0;
    flex-grow: 1;
    color: #0062FF;
}

.collapse {
    border: solid 1px #DFDFDF;
}

.collapseTable {
    width: 880px;
    font-size: 1.6rem;
    border-collapse: collapse;
    margin: 23px auto;
}

.collapseTblW {
    width: 100%;
    font-size: 1.2rem;
    margin: 0 auto;
}

.colTh {
    font-weight: 400;
    font-size: 1.5rem;
    border-bottom: solid 1px #0000001F;
    height: 29px;
    color: #5A607F;
}

.colThW {
    font-weight: 400;
    font-size: 1.4rem;
    border-bottom: solid 1px #0000001F;
    height: 29px;
    color: #5A607F;
}

.cTblTitle {
    font-weight: 400;
    font-size: 1.4rem;
    border-bottom: solid 1px #0000001F;
    height: 29px;
    color: #5A607F;
    text-align: start;
    padding-left: 45px;
}

/*.collapse table tbody td {
    font-weight: 400;
    font-size: 1.6rem;    
    height: 29px;
}*/

.tdDmy {
    border-bottom: solid 1px #FFFFFF;
    width: 22px;
}

.tdLfBd {
    text-align: start;
    border-bottom: solid 1px #0000001F;
    padding-left: 26px;
    width: 230px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.tdRtBd {
    text-align: end;
    border-bottom: solid 1px #0000001F;
    padding-right: 17px;
    width: 78px;
}

.tdGrBd {
    text-align: center;
    border-bottom: solid 1px #0000001F;
    width: 380px;
}

.tdTitle {
    text-align: start;
    border-bottom: solid 1px #0000001F;
    width: 180px;
    padding-left: 45px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.tdRatio {
    text-align: end;
    border-bottom: solid 1px #0000001F;
    padding-right: 25px;
    width: 60px;
}

.tdGraph {
    text-align: center;
    border-bottom: solid 1px #0000001F;
    width: 200px;
}

.message {
    width: 300px;
    height: 150px;
    padding-top: 150px;
    /*padding-left: 200px;*/
    text-align: center;
    font-size: 1.2rem;
}