.panel {
    width: 1140px;
    /*height: 690px;*/
    background-color: white;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
}

.head {
    display: flex;
}

.title {
    text-align: start;
    font-size: 2.0rem;
    font-weight: 700;
    margin-top: 20px;
    margin-left: 40px;
}

.close {
    position: absolute;
    top: 80px;
    left: 1240px;
}

.body {

}

.subtitle {
    font-size: 1.6rem;
    text-align: start;
    font-weight: 700;
    margin: 10px 0 10px 60px;
}

.baseRow {

}

.list {
    margin: 0 0 0 80px;
}

.table {
    width: 800px;
    border-collapse: collapse;
    margin-bottom: 60px;
}

.table tr {
    border-bottom: solid 1px #F1F1F5;
}

.thRow {
    color: #868E96;
    font-size: 1.2rem;
    height: 40px;
}

.thId {
    width: 70px;
}

.thName {
    width: 300px;
}

.thType {
    width: 150px;
}

.thLink {
    width: 100px;
}

.tdRow {
    color: #212529;
    font-size: 1.4rem;
    height: 30px;
}

.tdId {}

.tdName {}

.tdMap {}

.tdNone {
    color: red;
}

.tdLink {}

.iconBtn {
    color: #007BFF;
    background-color: white;
    border: solid 1px #F1F1F5;
    border-radius: 5px;
    padding: 5px;
}

.iconBtn:hover {
    cursor: pointer;
    background-color: #007bff80;
}

.iconBtn:focus {
    outline: 2px #007bff80 solid;
}

.iconSpace {
    width: 20px;
}

.csvBlock {
    padding: 0 250px 0 20px;
    justify-content: end;
    text-align: end;
}

.lotitle {
    font-size: 1.6rem;
    text-align: start;
    font-weight: 700;
    margin: 15px 0 0px 60px;
}

.spacer {
    padding: 0 7px 0 7px;
}

.fileInput {
    display: none;
}


