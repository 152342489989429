.pane {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 1px 4px #15223214;
    padding: 10x;
}

.head {
    background-color: white;
    border-radius: 6px;
    margin-bottom: 13px;
    padding: 10x;
}

.headScrolling {
    position: sticky;
    top: 0px;
    background-color: white;
    border-radius: 6px;
    width: 100%;
    margin-bottom: 13px;
    padding: 10x;
    z-index: 1000;
}

.headTop {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 15px 26px;
}

.title {
    font-size: 2.0rem;
    font-weight: 700;
    padding: 10px 10px 10px 0px;
    text-align: left;
    width: 170px;
}

.viewSel {
    padding: 10px;
}

.headMid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 15px 26px;
}

.headBottom {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    margin: 0 0 5px 26px;
}

.shopSel {
    width: 421px;
    margin-right: 38px;
}

.datepicker {
    font-size: 2.0rem;
    margin-right: 38px;
    height: 36px;
    width: 421px;
}

.customSelect {
    appearance: none;
    -webkit-appearance: none;
    background-image: url(./img/expand_more_FILL0_wght400_GRAD0_opsz24.svg);
    background-position: right 18px bottom 30%;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    border: 1px solid #d7dbec;
    border-radius: 4px;
    color: #5A607F;
    width: 421px;
    height: 34px;
    font-size: 2.0rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    padding: 0 0 2px 15px;
}

.customSelect:focus {
    outline: 2px #007bff solid;
}

.prevBtn {
    margin-right: 10px;
    height: 34px;
}

.nextBtn {
    height: 34px;
}

.customButton {
    background-color: white;
    border: solid 1px #007bff;
    border-radius: 6px;
    color: #007bff;
    font-size: 1.5rem;
    font-weight: 400;
    font-family: var(--atiko-font-helvetica-neue);
}
.customButton:hover {
    background-color: #f8f8f8;
    cursor: pointer;
}
.customButton:focus {
    outline: solid 2px #007bff80;
}
.customButton:disabled {
    background-color: #f8f8f8;
    border: solid 1px #007bff80;
    color: #007bff80;
    cursor: not-allowed;
}
.customButton:disabled:hover {
    cursor: not-allowed;
}
.customButtonName {
    vertical-align: top;
    padding: 0 0.3rem;
}
.customIconLeft {
    padding: 0.5rem 1rem 0rem 0.5rem;
}
.customIconRight {
    padding: 0.5rem 0.5rem 0rem 0.9rem;
}
.customNoIcon {
    padding: 0.45rem 1.4rem 0.7rem 1.4rem;
}

.body {
    padding: 20px 20px 20px 26px;
}

.chart4 {
    display: flex;
}

.mixed {
    display: flex;
}

.listChart {}

.chart3 {
    display: flex;
}

.mixMargin {
    display: flex;
    flex-wrap: wrap;
}

.holHeatmap {
    flex-grow: 1;
}

.areaStay {
    display: flex;
}

.stayTime {
    width: 340px;
    padding-bottom: 10px;
}

.lsTitle {
    font-size: 1.7rem;
    font-weight: 600;
    text-align: start;
}

.stayRatio {
    width: 340px;
}

.heatmap {
    padding: 13px 8px;
}

.hmTitle{
    font-size: 1.7rem;
    font-weight: 600;
    text-align: start;
}

.hmFlex {
    display: flex;
    cursor: pointer;
}

.space {
    height: 20px;
}

.lineGraphs {}

.error {
    position: relative;
    background-color: rgb(250, 219, 219);
    border: solid 1px red;
    border-radius: 6px;
    text-align: center;
    justify-content: center;
    width: 300px;
    height: 30px;
    color: red;
    font-size: 1.8rem;
    font-weight: 600;
    left: 250px;
    padding: 10px;
}

.errorBody {
    padding: 20px;
    font-size: 1.2rem;
    font-weight: 400;
    color: rgb(146, 2, 2);
}

.modalMain {
    padding: 5px;
}

.modalTop {
    display: flex;
    font-size: 2rem;
    font-weight: 600;
}

.modalTitle {
    flex-basis: 0;
    flex-grow: 1;
    padding: 10px;
}

.modalClose {
font-size: 1.2rem;
}

.modalBody{
    display: flex;
}
