.full {
    cursor: pointer;
    color: #868E96;
    /*background-color: white;*/
    background-image: url('./img/CheckboxFull.png');
    background-repeat: no-repeat;
    background-position: 0px;
    background-size: 14px;
}

.partof {
    cursor: pointer;
    color: #868E96;
    /*background-color: white;*/
    background-image: url('./img/CheckboxPartof.png');
    background-repeat: no-repeat;
    background-position: 0px;
    background-size: 14px;
}

.none {
    cursor: pointer;
    color: #868E96;
    /*background-color: white;*/
    background-image: url('./img/CheckboxNone.png');
    background-repeat: no-repeat;
    background-position: 0px;
    background-size: 14px;
}

.label {
    margin: 2px 0px 2px 20px;
    cursor: pointer;
}