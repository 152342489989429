.content {
    text-align: start;
}

.head {
    display: flex;
    margin: 8px 25px 4px 50px;
}

.legend {
    display: flex;
    justify-content: flex-start;
}

.dummy {
    /*margin: 0 auto;*/
    width: 280px;
}

.switch {
    /*justify-content: end;*/
}

.comment {
    margin: 0 0 0 50px;
    color: #5A607F;
    font-size: 1.2rem;
    height: 15px;
}

.body {
    margin: auto;
    padding-bottom: 10px;
}

.canvasWrap {
    position: relative;
    padding: 5px;
    margin: 0 0 7px 50px;
    box-sizing: content-box;
    width: 740px;
    /*height: 550x;*/
    box-shadow: 0 3px 6px #00000029;
    border-radius: 6px;
}

.canvasWrap::before {
    content: "";
    display: block;
    padding-top: 0%;
}

.canvasWrapNoShadow {
    position: relative;
    padding: 5px;
    margin: 0 0 7px 50px;
    box-sizing: content-box;
    width: 740px;
}

.canvasWrapNoShadow::before {
    content: "";
    display: block;
    padding-top: 0%;
}

.canvas {
    z-index: 1;
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
}

.aniCanvas {
    z-index: 10;
    position: absolute;
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    top: 5px;
    left: 5px;
}

.zoomBtn {
    z-index: 12;
    position: absolute;
    top: 0;
    right: 333px;
}