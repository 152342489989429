.panel {
    width: 1110px;
    height: 795px;
    background-color: white;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
}

.head {
    display: flex;
}

.title {
    flex-basis: 0;
    flex-grow: 1;
    text-align: start;
    font-size: 2.0rem;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 26px;
}

.close {
    padding: 10px;
}

.indicator {}

.steps {
    position: relative;
    top: -20px;
    left: 20px;
    text-align: center;
}

.body {
    padding-left: 45px;
    text-align: start;
}

.subTitle {
    font-size: 1.6rem;
    font-weight: 400;
    text-align: start;
}

.guide {
    margin: 20px 0 20px 100px;
    padding: 0 0 0 6px;
    font-size: 1.4rem;
    color: #5A607F;
    font-weight: 400;
}

.canvasWrap {
    position: relative;
    padding: 0;
    margin: 0 0 0 108px;
    box-sizing: content-box;
    width: 730px;
    height: 490px;
    /*border: solid 2px #5A607F;*/
    box-shadow: 0 0 12px #00000029;
}

.canvasWrap::before {
    content: "";
    display: block;
    padding-top: 0%;
}

.canvasBas {
    z-index: 1;
    position: absolute;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
}

.canvasMov {
    z-index: 11;
    position: absolute;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    border: solid 1px #F1F1F5;
    border-radius: 5xp;
}

.canvasShw {
    z-index: 50;
    position: absolute;
    box-sizing: content-box;
    padding: 0px;
    margin: 0;
    left: 0;
    top: 0;
    box-shadow: inset 0 0 0 45px rgba(0, 0, 0, 0.5);
}

.canvasDot {
    z-index: 60;
    position: absolute;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    left: 45.5px;
    top: 45.5px;
    background: linear-gradient(90deg, var(--atiko-gray-677-op50) 50%, transparent 50%),
        linear-gradient(90deg, var(--atiko-gray-677-op50) 50%, transparent 50%), 
        linear-gradient(0deg, var(--atiko-gray-677-op50) 50%, transparent 50%),
        linear-gradient(0deg, var(--atiko-gray-677-op50) 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
    background-position: 0px 0px, 640px 397px, 0px 400px, 637px 0px;
    animation: border-dance 10s infinite linear;
}

@keyframes border-dance {
    0% {
        background-position: 0px 0px, 640px 397px, 0px 400px, 637px 0px;
    }
    100% {
        background-position: 640px 0px, 0px 397px, 0px 0px, 637px 400px;
    }
}

.navi {
    position: relative;
    top: -10px;
    left: 835px;
}

.back {
    position: relative;
    top: -40px;
    left: 28px;
    width: 100px;
}

.zoombar {
    position: relative;
    top: -35px;
    left: 322px;
    z-index: 100;
}

.information {
    color: #868e96;
    font-size: 1.6rem;
    margin-left: 20px;
}

.information p {
    margin-block: 0.8rem;
}