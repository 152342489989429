.pane {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 1px 4px #15223214;
    /*width: 1120px;*/
    /*padding: 10x;*/

}

.head {
    display: flex;
    flex-wrap: wrap;
}

.title {
    font-size: 2.0rem;
    font-weight: 700;
    flex-basis: 0;
    flex-grow: 1;
    padding: 10px 10px 10px 26px;
    text-align: left;
}

.threshold {
    justify-content: flex-end;
    display: flex;
    margin-top: 5px;
    margin-right: 21px;
    color: #868E96
}

.setting {
    cursor: pointer;
    color: #1E5EFF;
}

.setting:hover {
    text-decoration: underline;
    color: #002080;
}

.dtControl {
    display: flex;
    flex-wrap: wrap;
    margin-left: 26px;
    margin-bottom: 24px;
    /*min-width: 1000px;*/
}

.datepicker {
    font-size: 2.0rem;
    margin-right: 16px;
    height: 36px;
    width: 420px;
}

.dayWrap {
    display: flex;
    min-width: 150px;
}

.prevBtn {
    margin-right: 10px;
}

.nextBtn {
    margin-right: 10px;
}

.empty {
    margin-right: auto;
    margin-left: auto;
}

.dspOrder {
    justify-content: right;
    margin-right: 34px;
}

.selControl {
    display: flex;
    margin-left: 26px;
    margin-right: 49px;
    margin-bottom: 35px;
}

.tabWrap {
    width: 360px;
}

.swWrap {
    width: 125px;
}

.btnGroup {}

.chartList {
    margin-left: 26px;
}

.react-datepicker {
    font-size: 1.6rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    color: #212529;
    border: solid 1px #D7DBEC;
}

.modalMain {
    width: 567px;
    height: 180px;
}

.modalTitle {
    font-size: 1.8rem;
    margin-left: 57px;
    margin-top: 34px;
}

.modalPicker {
    margin-left: 73px;
    margin-top: 22px;
    margin-bottom: 22px;
}

.modalControls {
    display: flex;
}

.modalCancel {
    margin-right: 15px;
}

.modalUpdate {
    margin-right: 47px;
}

.listPane {
    display: flex;
    flex-wrap: wrap;
    /*width: 1080px;*/
}

.error {
    position: relative;
    background-color: rgb(250, 219, 219);
    border: solid 1px red;
    border-radius: 6px;
    text-align: center;
    justify-content: center;
    width: 300px;
    height: 30px;
    color: red;
    font-size: 1.8rem;
    font-weight: 600;
    left: 250px;
    padding: 10px;
}

.errorBody {
    padding: 20px;
    font-size: 1.2rem;
    font-weight: 400;
    color: rgb(146, 2, 2);
}