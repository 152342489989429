.pane {
    width: 500px;
    height: 180px;
    border-radius: 6px;
    padding: 5px 20px 5px 20px;
}

.dialogHead {
    display: flex;
    color: var(--bs-orange);
    padding: 15px 20px 15px 20px;
}

.header {
    font-size: 1.8rem;
    padding: 0 0 0px 5px;
}

.icon {
    padding: 2px 5px 0 0;
}

.title {
}


.dialogBody {
    padding: 0 20px 20px 20px;
}

.dialogButton {
    padding: 0px 10px;
    text-align: center;
}
