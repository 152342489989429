.main {
    color: #131523;
    font-size: 1.2rem;
    margin: 0 0 0 44px;
    width: 1000px;
}

.table {
    border-collapse: collapse;
    width: 100%;
}


.table th {
    color: #7E84A3;
    font-weight: 400;
    border-bottom: solid 1px #E6E9F4;
}

.textRight {
    text-align: right;
    white-space: nowrap;
}

.thAreaName {
    width: 220px;
    text-align: center;
    padding: 0 0 4px 0;
}

.thTotal {
    width: 65px;
    text-align: right;
    padding-right: 5px;
}

.thRatio {
    width: 40px;
    text-align: right;
    padding-right: 1px;
}

.thSpc {
    width: 15px;
}

.thData {
    width: 65px;
    text-align: right;
    padding-right: 5px;
}

.thGraph {
    width: 380px;
}

.tdDiff {
    border-top: solid 1px #E6E9F4;
    text-align: right;
    padding-right: 5px;
}

.tdNameSng {
    text-align: center;
    color: #7E84A3;
    padding: 6px 0;
}

.tdNameCmp {
    text-align: center;
    color: #7E84A3;
}

.tdData {
    text-align: right;
    padding: 5px 5px 5px 0;
}

.spacer {
    padding: 5px 0;
}