.content {
    text-align: start;
}
.head {
    display: flex;
    margin: -11px 25px 4px 30px;
}
.legend {
    display: flex;
    justify-content: flex-start;
}
.dummy {
    width: 640px;
}

.dummyCmp {
    width: 600px;
}

.switch {
}

.comment {

}

.body {
}

.graph {
    width: 640px;
    height: 440px;
    margin: 10px auto;
    box-shadow: inset 0 0 6px #00000029;
    border-radius: 6px;
}

.graphCmp {
    width: 640px;
    height: 440px;
    margin: 10px 0 10px 143px;
    box-shadow: inset 0 0 6px #00000029;
    border-radius: 6px;
    background-color: white;
}

.filter {
    padding-top: 40px;
}

.filtername {
    font-size: 2.0rem;
    font-weight: 700; /*bold*/
    margin-left: 30px;
}

.filterNameCmp {
    font-size: 2.0rem;
    font-weight: 700;
    margin-left: 80px;
}

.list {
    margin-top: 35px;
    margin-left: 60px;
    padding-bottom: 35px;
}

.listCmp {
    margin-top: 35px;
    margin-left: 110px;
    padding-bottom: 35px;
}

.table {
    border-collapse: collapse;
    /*max-width: 1262px;*/
    width: 690px;
    margin: 0 10px 10px 10px;
    justify-content: center;
    text-align: center;
}

.table th {
    padding: 0px 10px 8px 10px;
    font-size: 12px;
    font-weight: 300;
    color: #7E84A3;
}

.table td {
    border-bottom: solid 1px #F1F1F5;
}

.colCheck {
    width: 50px;
}
.colId {
    width: 58px;
}
.colName {
    width: 244px;
}
.colParent {
    width: 210px;
}
.checkCol {
    padding: 0 0 0 40px;
}
.idColCmp {
    background-color: white;    
}
.dataCol {
    width: 216px;
    height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 0;
}
.dataColCmp {
    background-color: white;
    width: 216px;
    height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 0;
}
.message {
    width: 300px;
    height: 150px;
    padding-top: 150px;
    padding-left: 200px;
}