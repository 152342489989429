.icony {
    border-radius: 50%;
    background-color: #0058FF1A;
    border: solid 1px #0058FF1A;
    width: 62px;
    height: 62px;
}

.icony:hover {
    background-color: #0058FF50;
    border: solid 1px #0058FF50;
}

.inner {
    text-align: center;
    color: #0058FF;
    padding: 21px;
}

.smallWhite {
    border-radius: 50%;
    background-color: white;
    border: solid 1px var(--atiko-gray-dde);
    width: 40px;
    height: 40px;
}

.smallWhite:hover {
    background-color: var(--atiko-gray-dde-op50);
    border: solid 1px var(--atiko-gray-dde-op50);
}

.smallWhiteInner {
    text-align: center;
    color: var(--atiko-blue2);
    padding: 10px;
}
