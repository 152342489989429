.main {
    letter-spacing: 0.1rem;
    text-align: center;
    letter-spacing: 0rem;
}

.background {
    background-image: url(./login-bg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    background-blend-mode: lighten;
    z-index: 990;
}

.empty_row {
    height: 125px;
}

.panel {
    border-radius: 6px;
    height: 560px;
    width: 347px;
    margin: auto;
    background-color: white;
}

.header {
    height: 30px;
    background-color: rgb(19, 21, 35);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 18px;
}

.subpanel {
    margin: 36px 10px 10px 10px;
}

.title {
    margin: 10px;
    text-align: left;
    font-size: 1.6rem;
    font-weight: 400;
}

.form {
    margin: 24px 20px 20px 28px;
}

.label {
    text-align: left;
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 10px;
    letter-spacing: 0rem;
    font-family: "Helvetica Neue",
            Arial,
            "Hiragino Kaku Gothic ProN",
            "Hiragino Sans",
            Meiryo,
            sans-serif;
}

.frm_ctrl {
    display: block;
    width: 90%;
    height: 36px;
    padding: 0 12px;
    font-size: 1.4rem;
    font-weight: 400;
    color: #868D96;
    line-height: 1.5;
    border: 1px solid #ced4da;
    border-radius: 6px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-bottom: 24px;
    letter-spacing: 0.1rem;
    font-family: "Helvetica Neue",
            Arial,
            "Hiragino Kaku Gothic ProN",
            "Hiragino Sans",
            Meiryo,
            sans-serif;
}

.frm_ctrl:focus {
    outline: 2px #007bff solid;
}

.frm_pw {
    display: block;
    width: 90%;
    height: 36px;
    padding: 0 12px;
    font-size: 1.4rem;
    font-weight: 400;
    color: #868D96;
    line-height: 1.5;
    border: 1px solid #ced4da;
    border-radius: 6px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-bottom: 10px;
    letter-spacing: 0.1rem;
}

.frm_pw:focus {
    outline: 2px #007bff solid;
}

.startBtn {
    margin-top: 27px;
    margin-bottom: 0px;
    text-align: center;
}

.noError {
    font-size: 1.2rem;
    padding: 2px 0px;
}

.hasError {
    color: red;    
    font-size: 1.2rem;
    padding: 2px 0px;
}

.bottom {
    margin: 0px 20px 24px 20px;
    color: #868D96;
    font-size: 1.6rem;
    /*display: block;*/
    padding: 8px 10px;
    font-family: "Helvetica Neue",
            Arial,
            "Hiragino Kaku Gothic ProN",
            "Hiragino Sans",
            Meiryo,
            sans-serif;
}

.lnkitm {
    padding: 8px 10px 6px 10px;
    float: left;
}
.lnkitm a {
    color: #868D96;
    text-decoration: none;
}
/*
.forgot {
    height: 24px;
    font-size: 1.6rem;
    font-weight: 400;
    padding-left: 48px;
    cursor: pointer;
}*/
.empty {
    height: 4px;
    padding-left: 48px;
}

.spinner {
    display: flex;
    justify-content: center;
    padding-left: 7px;
}

.overlay {
    position: fixed;
    /* overlayの絶対位置を左上を起点にし幅と高さを100%にする */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(160, 160, 160, 0.1);
    /*backdrop-filter: blur(1px);*/
    /* 一番下のbody領域をぼやかす */
    z-index: 1040;
}

.waiting {
    position: absolute;
    top: 494px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 10px;
    border-radius: 50%;
    /* modalに影を作る */
    z-index: 1050;
}

/*
.policy {
    padding: 30px 0px 0px 0px;
    margin: 15px 90px 0px 0px;
}
.policy a {
    color: #868D96;
    text-decoration: none;
}*/

.pw_forget {
    padding: 50px 0px 0px 0px;
    margin: 1px 0px 0px 0px;
    font-family: var(--atiko-font-noto-sans);
}

.pw_forget a:link {
    text-decoration: none;
    color: #007bff;
    font-weight: 500;
}

.pw_forget a:hover {
    text-decoration: underline;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}