.contents {
    margin: 15px 0 25px 100px;
    padding: 0;
    width: 850px;
}

.main {
    display: flex;
    font-size: 1.2rem;
    color: #868E96;
    display: flex;
    margin: 1px 0 15px 0px;
    text-align: start;
    border-bottom: solid 1px #D7DBEC;
    /*width: 850px;*/
    padding-bottom: 2px;
}

.colCom {
    width: 200px;
    padding: 2px 20px 1px 0;
}

.colShop {
    width: 200px;
    padding: 2px 20px 1px 0;
}

.colDs {
    width: 240px;
    padding: 2px 20px 1px 0;
}

.colId {
    width: 90px;
    padding: 2px 20px 1px 0;
}

.colLay {
    width: 220px;
    padding: 2px 20px 1px 0;
}

.colAs {
    width: 120px;
    padding: 2px 20px 1px 0;
}

.select {}

.companySelect {
    width: 200px;
}

.shopSelect {
    width: 200px;
}

.dsSelect {
    width: 240px;
}

.layoutSelect {
    width: 220px;
}

.areaSetSelect {
    width: 240px;
}

.main select {
    border: solid 1px #D7DBEC;
    border-radius: 4px;
    padding: 4px 10px 5px 10px;
    appearance: none;
    -webkit-appearance: none;
    background-image: url(./img/chevron-down_icon.png);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    color: #868e96;
    /*width: 380px;*/
    height: 28px;
    font-size: 1.2rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
}

.main select:focus {
    outline: 2px #007bff solid;
}

.label {
    font-size: 1.2rem;
    color: #868E96;
    text-align: start;
    padding: 0 0 3px 10px;
}