.main {
    width: 800px;
    height: 600px;
    background-color: white;
    border-radius: 6px;
    text-align: start;
}

.head {
    display: flex;
}

.title {
    flex-basis: 0;
    flex-grow: 1;
    text-align: start;
    font-size: 2.0rem;
    font-weight: 700;
    margin: 30px 0 0 36px;
}

.close {
    padding: 10px;
}

.panel {
    margin: 10px 0 30px 60px;
    display: flex;
}

.body {
    padding: 50px 0 20px 100px;
}

.input {
    padding: 5px 0 5px 0px;
}

.label {
    font-weight: 600;
    padding: 5px;
    color: #868E96;
}

.guide {
    font-size: 1.2rem;
    padding-left: 10px;
}

.input input {
    display: block;
    width: 380px;
    height: 28px;
    font-size: 1.4rem;
    color: #212529;
    padding: 0px 15px;
    line-height: 1.5;
    border: 1px solid #ced4da;
    border-radius: 6px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.input input:focus {
    outline: 2px #007bff solid;
}

.error {
    color: red;
    font-size: 1.3rem;
    height: 20px;
    padding: 5px 0 0px 20px;
}

.check {
    padding: 5px 0 5px 0px;
}

.button {
    padding: 30px 0 10px 500px;
}

.report {
    margin: 0px 60px;
    width: 300px;
    height: 40px;
}

.errorReport {
    padding-top: 10px;
    margin: 0px 60px;
    background-color: rgb(250, 219, 219);
    border: solid 1px red;
    border-radius: 6px;
    text-align: center;
    justify-content: center;
    width: 300px;
    height: 40px;
    color: red;
    font-size: 1.8rem;
    font-weight: 600;
}