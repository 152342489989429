.main {
    background-color: #FFFFFF70;
    border: solid 2px #d7dbec;
    border-radius: 12px;
    width: 80px;
    text-align: center;
}

.zoomOut {
    display: inline-flex;
    padding: 4px;
    cursor: pointer;
}

.zoomReset {
    display: inline-flex;
    padding: 4px;
    cursor: pointer;
}

.zoomIn {
    display: inline-flex;
    padding: 4px;
    cursor: pointer;
}
