.section {
    display: flex;
    flex-wrap: wrap;
}

.pulldown {
    /*display: flex;
    flex-basis: 0;
    flex-grow: 1;
    justify-content: start;*/
}

.border {
    /*display: inline-block;*/
    border: solid 1px #d7dbec;
    border-radius: 4px;
    color: #868e96;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    width: 155px;
    height: 24px;
    padding: 5px 0px 0px 0px;
}

.label {
    padding: 3px 5px 3px 10px;
    margin-top: 0px;
}
.label:hover {
    cursor: pointer;
}

.icon {

}

.numpic {
    width: 182px;
    height: 190px;
    padding: 10px;
    background-color: white;
    border: 1px solid #eee;
    border-radius: 6px;
    box-shadow: 0px 5px 15px #44444F4F;
}

.overlay {
    width: 115px;
    height: 180px;
    padding: 10px;
    background-color: white;
    border: 1px solid #eee;
    border-radius: 6px;
    box-shadow: 0px 5px 15px #44444F4F;
}
.select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 110px;
    margin: 0px 0px 5px 0px;
    border: 0;
    outline: none;
    padding: 0;
    background: transparent;
    text-align: center;
    color: #868e96;
}

.slider {
    /*display: inline-block;
    width: 180px;*/
}
.range {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: none;
    height: 7px;
    width: 150px;
    background: #d7dbec;
    border-radius: 4px;
    border: solid 1px #d7dbec;
    margin-left: 10px;
}
.range::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #007BFF;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}
.range::-moz-range-thumb {
    background: #007BFF;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: none;
}
.range::-moz-focus-outer {
    border: 0;
}
.range:active::-webkit-slider-thumb {

}

.timeview {
    height: 20px;
    padding: 7px;
    color: #868e96;
    background-color: white;
    border: 1px solid #eee;
    border-radius: 6px;
    box-shadow: 0px 5px 15px #44444F4F;
}

.space {
    height: 12px;
}