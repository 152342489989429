.pane {
    background-color: white;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    height: 100%;
    padding: 1px 1px 20px 1px;
    margin-right: 10px;
    font-size: 1.2rem;
}

.switcher {
    margin-top: 26px;
    margin-left: 51px;
    margin-bottom: 10px;
    display: flex;
}

.content {}

.head {
    display: flex;
}

.warn {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
    margin: 1rem 5rem 1rem 5rem;
    padding: 1rem;
    border: solid 1px transparent;
    border-radius: 0.25rem;
}

.message {}

.title {
    color: #212529;
    font-size: 2.0rem;
    font-weight: 600;
    margin: 20px 0 20px 52px;
}

