.main {
    display: flex;
    width: 1100px;
}

.sideSpace {
    width: 50px;
}

.block {
    position: relative;
    width: 200px;
    height: 70px;
}

.centerLine {
    position: absolute;
    width: 200px;
    height: 20px;
    border-bottom: solid 2px #007BFF;
}

.sideCenterLine {
    position: relative;
    width: 200px;
    height: 20px;
}

.rightSideLine {
    position: absolute;
    width: 90px;
    height: 20px;
    left: 110px;
    border-bottom: solid 2px #007BFF;
}

.leftSideLine {
    position: absolute;
    width: 90px;
    height: 20px;
    left: 0px;
    border-bottom: solid 2px #007BFF;
}

.rivet {
    position: absolute;
    top: 0px;
    left: 84px;
    justify-content: center;
    
}

.label {
    position: absolute;
    top: 50px;
    left: 0px;
    color: #444444;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0rem;
    text-align: center;
    justify-content: center;
    width: 200px;
    white-space: nowrap;
    overflow: visible;
}

.longLabel {
    position: absolute;
    top: 50px;
    left: -20px;
    color: #444444;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0rem;
    text-align: center;
    justify-content: center;
    width: 200px;
    white-space: nowrap;
    overflow: visible;
}
