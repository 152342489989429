.menu {
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
    /*height: 750px;*/
    width: 200px;
    text-align: left;
    /*padding: 1px 1px 20px 1px;*/
    margin-right: 10px;
    font-size: 1.2rem;
    background-color: white;
}

.title {
    font-weight: 600;
    padding: 8px 8px 8px 20px;
}

.item1 {
    /*padding: 8px 0px 8px 31px;
    width: 160px;*/
}

.item2 {
    padding: 8px 0px 8px 31px;
    width: 169px;
}

.item2:hover {
    background-color: rgba(0, 98, 255, 0.05);
    cursor: pointer;
}

.item2_act {
    padding: 8px 0px 8px 28px;
    width: 169px;
    color: #0058FF;
    background-color: rgba(0, 98, 255, 0.05);
    border-left: solid 3px #0058FF;
    font-weight: 500;
}

.langSw {
    padding: 13px 0 10px 20px;
}

.langSw label {
    font-weight: 600;
    margin-right: 7px;
}

.langSw select {
    appearance: none;
    -webkit-appearance: none;
    background-image: url(./img/chevron-down_icon.png);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    border: 1px solid #d7dbec;
    border-radius: 4px;
    color: #252529;
    width: 100px;
    height: 25px;
    font-size: 1.2rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    padding-left: 15px;
    outline: none;
}

.langSw select:hover {
    outline: 2px #007bff solid;
}