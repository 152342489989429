.main {
    text-align: center;
    font-family: 'Poppins', 'Noto Sans JP', 'Helvetica Neue', sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    color: #5A607F;
    height: 95%;
}

.mainDisabled {
    text-align: center;
    font-family: 'Poppins', 'Noto Sans JP', 'Helvetica Neue', sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    color: #5A607F;
    height: 95%;
    cursor: not-allowed;
}

.badge {
    width: 136px;
    height: 16px;
    background-color: #007BFF;
    border-radius: 10px;
    font-family: 'Helvetica Neue','Noto Sans JP', sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    color: white;
    text-align: center;
    padding-bottom: 2px;
    letter-spacing: 0px;
    margin: 7px 0 0 8px;
}

.empty {
    width: 136px;
    height: 16px;
    margin: 7px 0 0 8px;
}

.lineTop {
    margin-top: 120px;
}

.lineMid {
    margin-bottom: 18px;
}

.button {
    border: solid 1px #d7dbec;
    border-radius: 4px;
    color: #5A607F;
    font-size: 1.4rem;
    font-weight: 400;
    width: 182px;
    font-family: 'Poppins', 'Noto Sans JP', sans-serif;
    box-shadow: 0 3px 6px #00000029;
    text-align: start;
    padding: 0 0 6px 0;
    margin: 0 auto;
    cursor: pointer;
}
.buttonDisabled {
    border: solid 1px #d7dbec;
    border-radius: 4px;
    color: #5A607F;
    font-size: 1.4rem;
    font-weight: 400;
    width: 182px;
    font-family: 'Poppins', 'Noto Sans JP', sans-serif;
    box-shadow: 0 3px 6px #00000029;
    text-align: start;
    padding: 0 0 6px 0;
    margin: 0 auto;
    cursor: not-allowed;
    background-color: #0000000C;
}

.input {
    display: none;
    cursor: pointer;
}

.icon {
    position: relative;
    top: 6px;
    cursor: pointer;
}
.iconDisabled {
    position: relative;
    top: 6px;
    cursor: not-allowed;
}

.label {
    padding: 0 0 6px 14px;
    cursor: pointer;
}

.labelDisabled {
    padding: 0 0 6px 14px;
    cursor: not-allowed;
}

.error {
    color: red;
    height: 22px;
}

.lineBottom {
    margin-top: 20px;
}

.spinner {
    padding-top: 195px;
    padding-left: 222px;
}

