.content {
    text-align: start;
}

.select {
    width: 370px;
    height: 26px;
    color: #868E96;
    font-size: 1.2rem;
    margin: 15px 0 0 52px;
}

.head {
    display: flex;
    margin: 5px 25px 15px 50px;
}

.legend {
    justify-content: start;
    margin-top: 30px;
}

.dummy {
    margin: 0 auto;
}

.switch {
    justify-content: end;
}

.comment {
    margin-bottom: 5px;
}

.swNote {
    font-size: 1.0rem;
}

.body {
    margin: 0px 50px 0 50px;
    height: 300px;
}

.chartWrapper {
    position: relative
}

.chartWrapper > canvas {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
}

.chartAreaWrapper {
    width: 800px;
    overflow-x: scroll;
}

.spiner {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    padding: 20px;
}