.main {
    background-color: #F5F5F8;
    border-radius: 5px;
    padding: 10px;
    color: #131523;
}

.title {
    font-size: 1.6rem;
    font-weight: 500;
}

.body {
    font-size: 1.2rem;
}

.points {

}

.table {
    margin: 10px 0 0 10px;
}

.tdLbl {
    text-align: center;
}

.tdXY {
    width: 20px;
}

.tdInp {
    width: 150px;
}

.tdSpc {
    width: 270px;
}

.picture {

}

.choice {
    padding: 0 50px 20px 80px;
}

.canvas {
    /*border: solid 1px #131523;*/
}

.attribute {
    padding: 0 10px;
}

.bottom {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.spc {
    width: 10px;
}

.input {
    width: 50px;
    height: 25px;
    font-size: 1.3rem;
    padding: 0 5px;
    font-weight: 400;
    color: #868D96;
    border: 1px solid #ced4da;
    border-radius: 4px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    letter-spacing: 0.1rem;
}

.input:focus {
    outline: 2px #007bff solid;
}

.select {
    padding: 4px 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    appearance: none;
    -webkit-appearance: none;
    border: 1px solid #d7dbec;
    border-radius: 4px;
    color: #868e96;
    width: 62px;
    font-size: 1.2rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    
    outline: none;
}

.select:hover {
    outline: 2px #007bff solid;
}

.select option {
    padding: 2px 15px;
}

.label {
    color: #868e96;
    font-size: 1.2rem;
}

.direction {
    position:relative;
    top: 3px;
    left: -5px;
    color: rgb(30, 94, 255);
}