.limeGreenTxt {
    color: #1FD286;
    text-align: right;
}

.mildBlueTxt {
    color: #1E5EFF;
    text-align: right;
}

.main4line {
    width: 200px;
    height: 200px;
    padding: 0 10px 10px 0;
    /*margin: 1px 7px 2px 6px;*/
}

.main3line {
    width: 200px;
    height: 230px;
    /*margin: 1px 7px 2px 6px;*/
}

.title {
    font-size: 1.7rem;
    font-weight: 600;
    text-align: start;
    margin-top: 10px;
    margin-bottom: 12px;
    color: #212529;
}

.table {
    width: 200px;
}

.tableMain {
    width: 150px;
    padding: 0 0 0 30px;
    font-size: 1.2rem;
    color: #7E84A3;
    margin: 0 10px;
}

.dataName {
    width: 35px;
    text-align: center;
    white-space: nowrap;
}

.dataValue {
    text-align: right;
    font-size: 1.4rem;
    color: #131523;
}

.dataValueHot {
    color: #1FD286;
    font-size: 1.4rem;
    text-align: right;
}

.dataValueCold {
    color: #1E5EFF;
    font-size: 1.4rem;
    text-align: right;
}

.spiner {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    padding: 20px;
}

.graph {
    margin: 0 20px;
    padding-bottom: 20px;
}