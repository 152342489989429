.listMain {}

.head {
    margin-left: 12px;
}

.title {
    margin-bottom: 4px;
    display: flex;
    flex-wrap: nowrap;
}

.titleText {
    font-size: 2.0rem;
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
}

.note {
    font-size: 1.0rem;
    font-weight: 400;
    width: 280px;
    display: flex;
    padding-top: 10px;
}

.control {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 5px;
}

.ctrlLeft {
    display: flex;
    justify-content: flex-start;
    color: #868E96;
}

.ctrlLabel {
    padding-top: 5px;
}

.ctrlCount {
    padding-top: 5px;
}

.ctrlSpc {
    width: 30px;
}

.mid {
    display: flex;
    margin: 0 auto;
}

.ctrlBtns {
    display: flex;
    justify-content: flex-end;
    margin-right: 59px;
}

.filterBtn {
    display: flex;
}

.spacing {
    width: 10px;
    display: flex;
}

.downloadBtn {
    display: flex;
}

.tablePane {
    padding: 0 14px;
    margin-bottom: 10px;
}

.tablePaneCmp {
    padding: 0 14px;
    margin-bottom: 10px;
    margin-right: 10px;
    background-color: white;
    box-shadow: inset 0px 3px 6px #00000029;
}

.table {
    border-collapse: collapse;
}

.table td {
    border-bottom: solid 1px #F1F1F5;
}

.listFoot {
    margin-bottom: 5px;
    display: flex;
}

.pagination {
    margin-left: 50px;
}

.numOfPage {
    font-size: 1.3rem;
    color: #868e96;
    margin-right: 60px;
}

.numOfPage label {
    padding-right: 10px;
}

.numSelect {
    appearance: none;
    -webkit-appearance: none;
    background-image: url(./img/chevron-down_icon.png);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    border: 1px solid #d7dbec;
    border-radius: 4px;
    color: #868e96;
    width: 100px;
    height: 29px;
    font-size: 1.3rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    padding-left: 10px;
}

.numSelect:focus {
    outline: 2px #007bff solid;
}

.numSelect:hover {
    cursor: pointer;
}

.tableRow {
    color: #868E96;
}

.tableDataRow {
    color: #131523;
}

.tableClerkRow {
    color: #238f28;
}

.thBulk {
    font-weight: 400;
    width: 50px;
    padding: 0 15px;
}

.thId {
    font-weight: 400;
    width: 94px;
    padding: 0 15px;
}

.thDatetime {
    font-weight: 400;
    width: 94px;
    padding: 0 25px;
}

.thTime {
    font-weight: 400;
    width: 94px;
    padding: 0 5px;
}

.thNum {
    font-weight: 400;
    width: 100px;
    /*padding: 0 0px;*/
}

.thBuy {
    font-weight: 400;
    width: 94px;
    padding: 0 15px;
}

.thLink {
    font-weight: 400;
    width: 94px;
}

.thPack {
    display: flex;
    justify-content: center;
}

.thLabel {
    display: flex;
    padding-top: 5px;
}

.tdChk {
    height: 28px;
    padding-left: 38px;
}

.tdHid {
    text-align: center;
}

.tdStr {
    text-align: center;
}

.tdEnd {
    text-align: center;
}

.tdTme {
    text-align: center;
}

.tdCnt {
    text-align: center;
}

.tdBuy {
    text-align: center;
}

.tdDat {
    text-align: center;
}

.dtIcon {
    color: #007BFF;
}

.noData {
    padding: 5px;
    margin: 0 auto;
    text-align: center;
}

/*.table tbody tr:hover {
    background-color: #D1E6FA15;
}*/

.greenTxt {
    font-size: 1.0rem;
    color: #238f28;
    padding: 7px 180px 0px 0px;
}

.tableFireRow {
    background-color: #DC143C;
}

.downloadButton {
    border: solid 1px #d7dbec;
    border-radius: 4px;
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    padding-top: 3px;
    background-color: white;
    color: #868e96;
    width: 92px;
}

.downloadButton:hover {
    color: #868e96;
    background-color: #DEE2E6;
}

.downloadButton:focus {
    outline: none;
    border: solid 1px #007bff80;
}

.hiddenLink {
    display: none;
}

.spiner {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    padding: 20px;
}
