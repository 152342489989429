.border {
    border: solid 1px #d7dbec;
    border-radius: 4px;
    color: #868e96;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.5;
    width: 210px;
    height: 23px;
    padding: 4px 5px 2px 0px;
}
.border:hover {
    cursor: pointer;
}

.border:focus {
    border: solid 1px #868e96;
    color: #868e96;
}

.label {
    padding: 3px 13px 3px 15px;
    margin-top: 20px;
    vertical-align: top;
}
.label:hover {
    cursor: pointer;
}

.icon {
    font-size:20px;
}
.icon:hover {
    cursor: pointer;
}
