.panel {
    width: 1100px;
    background-color: white;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
}

.title {
    text-align: start;
    font-size: 2.0rem;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 26px;
}

.entry {
    text-align: start;
    margin: 37px 0 22px 34px;
}

.bodyLabel {
    font-size: 1.6rem;
    text-align: start;
    font-weight: 700;
    margin: 10px 0 10px 34px;
}

.main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 50px 0 34px;
}

.mainLeft {
    width: 600px;
}

.mainRight {
    width: 400px;
}

.choice {
    text-align: end;
    font-size: 1.2rem;
    color: #868e96;
    margin-bottom: 10px;
    width: 500px;
}

.label {
    width: 250px;
    text-align: end;
}

.comSelect {
    appearance: none;
    -webkit-appearance: none;
    background-image: url(./img/chevron-down_icon.png);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    border: 1px solid #d7dbec;
    border-radius: 4px;
    color: #868e96;
    width: 350px;
    height: 29px;
    font-size: 1.2rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    padding-left: 15px;
}

.comSelect:focus {
    outline: 2px #007bff solid;
}

.table {
    width: 550px;
    margin: 30px 10px 5px 20px;
    border-collapse: collapse;
}

.table thead tbody {
    display: block;
}

.table thead {
    font-size: 1.2rem;
    color: #868E96;
}

.table tbody {
    overflow-x: hidden;
    overflow-y: scroll;
    color: #131523;
    font-size: 1.4rem;
}

.table tr {
    border-bottom: solid 1px #F1F1F5;
}

.table th {
    padding: 10px;
}

.thRow {

}

.thId {
    width: 30px;
}

.thName {
    width: 100px;
}

.thLink {
    width: 20px;
}

.tdRow {

}

.tdRow:hover {
    background-color: #F0F0F0;
}

.tdRowHi {
    background-color: #F0E0FF;
}

.tdId {
    width: 30px;
}

.tdName {
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 0;
}

.tdLink {
    width: 20px;
}

.wrap {
    position: relative;
    padding: 5px;
    box-sizing: content-box;
    width: 380px;
    border: solid 2px #5A607F;
    border-radius: 6px;
    margin-top: 150px;
}

.canvas {
    z-index: 1;
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
}

.aniCanvas {
    z-index: 10;
    position: absolute;
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    top: 5px;
    left: 5px;
}

.zoomBtn {
    z-index: 12;
    position: absolute;
    top: 5px;
    right: 150px;
}

.icon {
    color: #007BFF;
    cursor: pointer;
}
