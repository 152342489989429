.dialog {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100000;
}

.overlayPage {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
}

.container {
    position: relative;
    z-index: 100001;
    background-color: white;
    border-radius: 6px;
}

.containerPage {
    position: relative;
    z-index: 100001;
    background-color: white;
    border-radius: 6px;
    width: 1300px;
    max-height: 1350px;
}
