.main {
    width: 75vw;
    height: 438px;
    padding-left: 4px;
}

.head {
    text-align: start;
    font-family: 'Poppins', 'Noto Sans JP', sans-serif;
    font-size: 1.7rem;
    font-weight: 600;
}

.scrollable {
    overflow-x: scroll;
    overflow-y: visible;
}

.graph {
    max-width: 1300px;
    padding: 12px 0 0 40px;
    height: 225px;
}

.graph canvas {
    height: 215px;
}

.list {
    max-width: 1300px;
    padding: 15px 0 0 30px;
    white-space: nowrap;
}

.list table {
    border-collapse: collapse;
    table-layout: fixed;
}

.list table thead {
    color: #131523;
    background-color: #F5F6FA;
    font-family: 'Poppins', 'Noto Sans JP', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    height: 48px;
}

.list table th {
    width: 97px;
}

.list table td {
    color: #131523;
    font-family: 'Poppins', 'Noto Sans JP', sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    height: 56px;
}

.unitLeft {
    color: #7E84A3;
    font-size: 1.2rem;
    text-align: start;
    padding-left: 20px;
}

.unitRight {
    color: #7E84A3;
    font-size: 1.2rem;
    text-align: end;
    padding-right: 20px;
}