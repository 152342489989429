.efctBtn {
    position: relative;
    width: 60%;
    height: 2.0rem;
    background: #DC3545;
    border-radius: 4px;
    padding: 5px 10px;
    margin-left: 60px;
    transition: all 0.3s ease-in;
}

.efctBtn:hover {
    width: 50%;
    height: 2.5rem;
    margin-top: -5px;
    margin-left: 70px;
    cursor: pointer;
}

.efctBtn span {
    position: absolute;
    top: 0px;
    left: 15px;
    width: 130px;
    text-align: center;
    margin: 5px 0px;
    font-size: 1.3rem;
    color: white;
    font-weight: 400;

}

.efctBtn span:nth-child(2) {
    display: none;
}

.efctBtn:hover span:nth-child(1) {
    display: none;
}

.efctBtn:hover span:nth-child(2) {
    display: block;
    left: 5px;
    font-size: 1.5rem;
}

.efctBtnDisabled {
    position: relative;
    width: 60%;
    height: 2.0rem;
    background: #DC35459F;
    border-radius: 4px;
    padding: 5px 10px;
    margin-left: 60px;
    transition: all 0.3s ease-in;
}

.efctBtnDisabled:hover {
    cursor: not-allowed;
}

.efctBtnDisabled span {
    position: absolute;
    top: 0px;
    left: 15px;
    width: 130px;
    text-align: center;
    margin: 5px 0px;
    font-size: 1.3rem;
    color: white;
    font-weight: 400;
}

.efctBtnDisabled span:nth-child(2) {
    display: none;
}
