.contents {}

.pane {
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    text-align: left;
    padding: 1px 1px 0px 5px;
    margin-right: 10px;
    font-size: 1.2rem;
    background-color: white;
    flex: 0 0 50%;
    max-width: 539px;
    margin-bottom: 14px;
}

.pane2 {
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    text-align: left;
    padding: 1px 1px 0px 5px;
    margin-right: 10px;
    font-size: 1.2rem;
    background-color: white;
    flex: 0 0 50%;
    margin-bottom: 14px;
    min-width: 1012px;
}

.head {
    display: flex;
    flex-wrap: wrap;
}

.title {
    width: 264px;
    font-size: 2.0rem;
    font-weight: 800;
    padding: 9px;
    text-align: left;
    margin: 1px 5px 5px 12px;
}

.cmpSwitch {
    padding: 5px;
    margin-bottom: 5px;
    margin-right: 63px;
}

.cmpSwitch2 {
    padding: 5px;
    margin-bottom: 6px;
}

.main {}

.main2 {
    display: flex;
    flex-wrap: wrap;
    border-left: 1px;
}

.dummy {}

.query {
    display: block;
}

.result {
    display: block;
    width: 1120px;
}

.result2 {
    display: flex;
    width: 1120px;
}

.waiting {
    text-align: center;
    width: 1120px;
    justify-content: center;
}

.space {
    width: 6px;
    height: 10px;
}

.error {
    position: relative;
    background-color: rgb(250, 219, 219);
    border: solid 1px red;
    border-radius: 6px;
    text-align: center;
    justify-content: center;
    width: 500px;
    height: 100px;
    color: red;
    font-size: 1.8rem;
    font-weight: 600;
    left: 250px;
    padding: 10px;
}

.errorBody {
    padding: 20px;
    font-size: 1.2rem;
    font-weight: 400;
    color: rgb(146, 2, 2);
}
