.accordionItem {
    border: solid 1px rgb(0 0 0 / 13%);
    margin: 2px;
    border-radius: 4px;
}

.accItems {
    padding: 10px 15px;
}

.dsHead {
    display: flex;
    margin: 1px 30px 10px 30px;
    text-align: start;
    border: solid 1px #F1F1F5;
    border-radius: 4px;
    background-color: #F1F1F5;
}
.dsLeft {
    width: 80px;
    padding: 10px 20px;
    font-size: 1.4rem;
}
.dsHdLable {
    font-size: 1.3rem;
    color: #868E96;
}
.dsMid {
    width: 400px;
    padding: 10px 20px;
    font-size: 1.4rem;
}
.dsRight {
    padding: 10px 20px 1px 20px;
}
/*
.accordionBtn {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    border: solid 1px rgb(0 0 0 / 13%);
    transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        border-radius 0.15s ease;
    outline: 0;
    background-color: white;
    overflow-anchor: none;
    cursor: pointer;
}

.accordionBtnAct {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    border: 0;
    transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        border-radius 0.15s ease;
    outline: 0;
    overflow-anchor: none;
    color: #0c63e4;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    cursor: pointer;
}

.accordionBtnAct:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 2px rgb(13 110 253 / 25%);
    outline: 0;
}

.accordionBtn::after {
    width: 16px;
    height: 16px;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 16px;
    transition: transform 0.2s ease-in-out;
}

.accordionBtnAct::after {
    width: 16px;
    height: 16px;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 16px;
    transform: rotate(-180deg);
    transition: 0.2s ease-in-out;
}
*/
/*本番ボタン（赤系）*/
.production {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    border: solid 1px rgb(0 0 0 / 13%);
    transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        border-radius 0.15s ease;
    outline: 0;
    overflow-anchor: none;
    color: #F18079;
    background-color: #FBE6E6;
    cursor: pointer;
}

.production:focus {
    border-color: #F18079;
    box-shadow: 0 0 0 2px rgba(253, 65, 13, 0.25);
    outline: 0;
}

.production::after {
    width: 16px;
    height: 16px;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e4630c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 16px;
    transition: transform 0.2s ease-in-out;
}

.productionExp {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    border: 0;
    transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        border-radius 0.15s ease;
    outline: 0;
    overflow-anchor: none;
    color: #F18079;
    background-color: #FBE6E6;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    cursor: pointer;
}

.productionExp:focus {
    border-color: #F18079;
    box-shadow: 0 0 0 2px rgba(253, 65, 13, 0.25);
    outline: 0;
}

.productionExp::after {
    width: 16px;
    height: 16px;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e4630c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 16px;
    transform: rotate(-180deg);
    transition: 0.2s ease-in-out;
}

/*適用待ちボタン（青系）*/
.ready {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    border: 0;
    transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        border-radius 0.15s ease;
    outline: 0;
    overflow-anchor: none;
    color: #3878E8;
    background-color: #E7F1F7;
    cursor: pointer;
}

.ready:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 2px rgb(13 110 253 / 25%);
    outline: 0;
}

.ready::after {
    width: 16px;
    height: 16px;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 16px;
    transition: transform 0.2s ease-in-out;
}

.readyExp {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    border: 0;
    transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        border-radius 0.15s ease;
    outline: 0;
    overflow-anchor: none;
    color: #3878E8;
    background-color: #E7F1F7;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    cursor: pointer;
}

.readyExp:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 2px rgb(13 110 253 / 25%);
    outline: 0;
}

.readyExp::after {
    width: 16px;
    height: 16px;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 16px;
    transform: rotate(-180deg);
    transition: 0.2s ease-in-out;
}

/*その他ボタン（白系）*/
.other {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    border: solid 1px rgb(0 0 0 / 13%);
    transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        border-radius 0.15s ease;
    outline: 0;
    background-color: white;
    overflow-anchor: none;
    cursor: pointer;
}

.other:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 2px rgb(13 110 253 / 25%);
    outline: 0;
}

.other::after {
    width: 16px;
    height: 16px;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 16px;
    transition: transform 0.2s ease-in-out;
}

.otherExp {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    border: solid 1px rgb(0 0 0 / 13%);
    transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        border-radius 0.15s ease;
    outline: 0;
    background-color: white;
    overflow-anchor: none;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    cursor: pointer;
}

.otherExp:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 2px rgb(13 110 253 / 25%);
    outline: 0;
}

.otherExp::after {
    width: 16px;
    height: 16px;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 16px;
    transform: rotate(-180deg);
    transition: 0.2s ease-in-out;
}

.title {
    padding: 0 1px;
    width: 100px;
}

.name {
    padding: 0 20px;
}


.dialogPane {
    width: 520px;
    height: 208px;
    border-radius: 6px;
}

.dialogPaneDel {
    width: 520px;
    height: 250px;
    border-radius: 6px;
}

.dialogHead {
    font-size: 1.8rem;
    padding: 34px 0 22px 57px;
}

.dialogBody {
    padding: 0 0 22px 57px;
}

.nameInput {
    color: #868E96;
    font-size: 1.6rem;
}

.nameInput input {
    width: 400px;
    height: 25px;
    font-size: 1.3rem;
    padding: 0 12px;
    font-weight: 400;
    color: #868D96;
    border: 1px solid #ced4da;
    border-radius: 6px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    letter-spacing: 0.1rem;
}

.nameInput input:focus {
    outline: 2px #007bff solid;
}

.dialogTitle {
    font-size: 1.4rem;
    color: #868D96;
    width: 130px;
    padding: 5px 10px;
}

.dialogData {
    font-size: 1.6rem;
    color: #131523;
}

.dialogBottom {
    text-align: end;
    padding: 0 41px 0 0px;
}

.spc {
    padding: 10px 15px;
}

.iconBtn {
    color: #007BFF;
    background-color: white;
    border: solid 1px #F1F1F5;
    border-radius: 5px;
    padding: 5px;
}

.iconBtn:hover {
    cursor: pointer;
    background-color: #007bff80;
}

.iconBtn:focus {
    outline: 2px #007bff80 solid;
}

.iconBtnRed {
    color: #F18079;
    background-color: white;
    border: solid 1px #F1F1F5;
    border-radius: 5px;
    padding: 5px;
}

.iconBtnRed:hover {
    cursor: pointer;
    background-color: #F1807980;
}

.iconBtnRed:focus {
    outline: 2px #F1807980 solid;
}
