.panel {
    width: 1110px;
    height: 770px;
    background-color: white;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
}

.head {
    display: flex;
}

.title {
    flex-basis: 0;
    flex-grow: 1;
    text-align: start;
    font-size: 2.0rem;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 26px;
}

.close {
    padding: 10px;
}

.indicator {}

.steps {
    position: relative;
    top: -20px;
    left: 20px;
    text-align: center;
}

.body {
    padding-left: 45px;
    text-align: start;
}

.subTitle {
    font-size: 1.6rem;
    font-weight: 400;
    text-align: start;
}

.guide {
    margin: 20px 0 20px 100px;
    padding: 0 0 0 6px;
    font-size: 1.4rem;
    color: #5A607F;
    font-weight: 400;
}

.strong {
    font-weight: 600;
}

.canvasWrap {
    position: relative;
    padding: 0;
    margin: 0 0 0 108px;
    box-sizing: content-box;
    width: 754px;
    height: 436px;
    border: solid 2px #5A607F;
    box-shadow: 0 0 12px #00000029;
}

.canvasWrap::before {
    content: "";
    display: block;
    padding-top: 0%;
}

.canvasBas {
    z-index: 1;
    position: absolute;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
}

.canvasMov {
    z-index: 15;
    position: absolute;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    opacity: 0.5;
}

.canvasCrs {
    z-index: 11;
    position: absolute;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    border: solid 1px #F1F1F5;
    border-radius: 5xp;
    opacity: 0.5;
}

.zoombar {
    position: relative;
    top: -35px;
    left: 340px;
    z-index: 100;
}

.minus {
    justify-content: start;
}

.range {
    justify-content: center;
}

.plus {
    justify-content: end;
}

.messages {
    position: relative;
    top: -26px;
    padding: 5px 0 0 115px;
    color: var(--atiko-gray-567);
    font-size: 1.4rem;
    font-weight: 400;
    width: 807px;
    height: 68px;
}

.oneLine {
    height: 16px;
}

.navi {
    position: relative;
    top: -80px;
    left: 890px;
}

.sidebar {
    position: relative;
    top: -420px;
    left: 880px;
}

.back {
    position: relative;
    top: -263px;
    left: 28px;
    width: 100px;
}