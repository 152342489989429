.listMain {}

.head {
    margin-left: 12px;
}

.title {
    margin-bottom: 4px;
    display: flex;
    flex-wrap: nowrap;
}

.titleText {
    font-size: 1.4rem;
    font-weight: 700;
    display: flex;
    justify-content: start;
    padding: 3px 0 4px 0;
}

.note {
    font-size: 1.0rem;
    font-weight: 400;
    width: 280px;
    display: flex;
    padding-top: 10px;
}

.control {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 5px;
}

.ctrlLeft {
    display: flex;
    justify-content: start;
    color: #868E96;
}

.ctrlLabel {
    display: flex;
    padding-top: 5px;
}

.ctrlCount {
    display: flex;
    padding-top: 5px;
}

.mid {
    display: flex;
    margin: 0 auto;
}

.ctrlBtns {
    display: flex;
    justify-content: end;
    margin-right: 59px;
}

.filterBtn {
    display: flex;
}

.tablePane {
    padding: 0 14px;
    margin-bottom: 10px;
}

.tablePaneCmp {
    padding: 0 14px;
    margin-bottom: 10px;
    margin-right: 10px;
    background-color: white;
    box-shadow: inset 0px 3px 6px #00000029;
}

.tablePaneShort {
    height: 453px;
    overflow-y: scroll;
    padding: 0 14px;
    margin-bottom: 10px;
}

.tablePaneCmpShort {
    height: 453px;
    overflow-y: scroll;
    padding: 0 14px;
    margin-bottom: 10px;
    margin-right: 10px;
    background-color: white;
    box-shadow: inset 0px 3px 6px #00000029;
}

.table {
    border-collapse: collapse;
}

.table tr {
    height: 22px;
}

.table td {
    border-bottom: solid 1px #F1F1F5;
}

.noData {}

.tableRow {
    color: #868E96;
}

.tableDataRow {
    color: #131523;
}

.tableClerkRow {
    color: #238f28;
}

.tableFireRow {
    background-color: #DC143C;
}

.thBulk {
    font-weight: 400;
    width: 46px;
    padding: 0 1px;
}

.thId {
    font-weight: 400;
    width: 90px;
    padding: 0 13px;
}

.thDatetime {
    font-weight: 400;
    width: 94px;
    padding: 0 25px;
}

.thTime {
    font-weight: 400;
    width: 94px;
    padding: 0 5px;
}

.thNum {
    font-weight: 400;
    width: 100px;
}

.thBuy {
    font-weight: 400;
    width: 94px;
    padding: 0 15px;
}

.thLink {
    font-weight: 400;
    width: 94px;
}

.thPack {
    display: flex;
    justify-content: center;
}

.thLabel {
    display: flex;
    padding-top: 5px;
}

.tdChk {
    height: 22px;
    padding-left: 20px;
}

.tdHid {
    text-align: center;
}

.tdStr {
    text-align: center;
}

.tdEnd {
    text-align: center;
}

.tdTme {
    text-align: center;
}

.tdCnt {
    text-align: center;
}

.tdBuy {
    text-align: center;
}

.tdDat {
    text-align: center;
    cursor: pointer;
}

.dtIcon {
    color: #007BFF;
}

.noData {
    padding: 5px;
    margin: 0 auto;
    text-align: center;
}

.spiner {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    padding: 20px;
}
