.border {
    border: solid 1px #D7DBEC;
    border-radius: 4px;
    color: #868e96;
    font-weight: 450;
    width: 80%;
    padding: 3px 10px 3px 10px;
    margin: 5px 0px 4px 0px;
}

.series {
    display: inline-flex;
    padding: 2px 7px 2px 5px;
}
