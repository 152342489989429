.panel {
    background-color: white;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
}

.title {
    text-align: start;
    font-size: 2.0rem;
    font-weight: 700;
    margin-left: 26px;
    padding-top: 9px;
}

.control {
    text-align: start;
    margin-top: 15px;
    margin-left: 26px;
}

.btnSpace {
    padding: 13.4px;
}

.filter {
    text-align: start;
    margin: 35px 0 0px 75px;
}

.comSelection {
    font-size: 1.2rem;
    color: #868e96;
    margin-bottom: 10px;
}

.companyName {
    padding: 0 0 0 20px;
}

.companySelect {
    appearance: none;
    -webkit-appearance: none;
    background-image: url(./img/chevron-down_icon.png);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    border: 1px solid #d7dbec;
    border-radius: 4px;
    color: #868e96;
    width: 421px;
    height: 29px;
    font-size: 1.2rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    padding-left: 15px;
}

.companySelect:focus {
    outline: 2px #007bff solid;
}

.shopSelection {
    font-size: 1.2rem;
    color: #868e96;
    height: 29px;
}

.shopSelectText {
    font-size: 1.2rem;
    color: #868e96;
    padding-top: 6px;
    height: 23px;
}

.hasNoShop {
    font-size: 1.4rem;
    color: #131523;
    padding: 2px 0 10px 75px;
}

.shopLabel {
    padding-right: 15px;
}

.shopSelect {
    appearance: none;
    -webkit-appearance: none;
    background-image: url(./img/chevron-down_icon.png);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    border: 1px solid #d7dbec;
    border-radius: 4px;
    color: #868e96;
    width: 421px;
    height: 29px;
    font-size: 1.2rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    padding-left: 15px;
}

.shopSelect:focus {
    outline: 2px #007bff solid;
}

.shopSelectDisabled {
    height: 29px;
}

.listing {
    font-size: 1.2rem;
    font-weight: 400;
    padding: 20px 0;
}

.error {
    color: red;
    background-color: rgb(250, 219, 219);
    font-size: 1.4rem;
    font-weight: 600;
    justify-content: center;
    margin: 10px 100px 0 100px;
    padding: 15px;
    border: solid 1px red;
    border-radius: 4px;
}