.dialogPane {
    width: 520px;
    height: 208px;
    border-radius: 6px;
}

.dialogHead {
    font-size: 1.8rem;
    padding: 34px 0 22px 57px;
}

.dialogBody {
    padding: 0 0 22px 57px;
}

.dialogStDtPan {
    width: 300px;
    padding: 5px;
    display: inline-flex;
}

.dialogStDtLbl {
    width: 50px;
    padding: 10px 10px 0 10px;
}

.dialogStDtPick {
    border: solid 1px #F1F1F5;
    border-radius: 4px;
    width: 235px;
    padding: 4px 0 1px 5px;
}

.dialogBottom {
    text-align: end;
    padding: 0 41px 0 0px;
}

.spc {
    padding: 10px 15px;
}
