.pagination {
    height: 29px;
    font-size: 14px;
    display: flex;
    text-align: center;
    background-color: white;
    color: #868e96;
}

.prev {
    color: #007BFF;
    border: solid 1px #DEE2E6;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 102px;
    padding-top: 2px;
}

.prev:hover {
    background-color: #DEE2E6;
    cursor: pointer;
}

.disablePrev {
    border: solid 1px #DEE2E6;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 102px;
    padding-top: 2px;
}

.page {
    color: #007BFF;
    border: solid 1px #DEE2E6;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 2px;
}

.page:hover {
    background-color: #DEE2E6;
    cursor: pointer;
}

.active {
    background-color: #007BFF;
    color: white;
    border: solid 1px #007BFF;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 2px;
}

.ellipsis {
    border: solid 1px #DEE2E6;
    padding-left: 12px;
    padding-right: 12px;
}

.next {
    color: #007BFF;
    background-color: white;
    border: solid 1px #DEE2E6;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 102px;
    padding-top: 2px;
}

.next:hover {
    background-color: #DEE2E6;
    cursor: pointer;
}

.disableNext {
    background-color: white;
    border: solid 1px #DEE2E6;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 102px;
    padding-top: 2px;
}