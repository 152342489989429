.main {
    background-color: #F4F7FC;
}

.pane {
    background-color: white;
    margin-top: 163px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 6px;
    box-shadow: 0 1px 4px #15223214;
    width: 580px;
    height: 357px;
}

.head {
    display: flex;
    margin-bottom: 28px;
}

.title {
    margin-top: 19px;
    padding-top: 10px;
    margin-left: 49px;
    font-size: 2.0rem;
    font-weight: 700;
    color: #212529;
    flex-basis: 0;
    flex-grow: 1;
}

.body {
    margin: 0 30px;
    font-size: 1.4rem;
    font-weight: 400;
    color: #868E96;
}

.text {
    color: #212529;
    margin: 17px 0 38px 50px;
    line-height: 24px;
}

.button {
    padding: 40px;
    text-align: center;
}

.spinner {
    display: flex;
    justify-content: center;
}

.overlay {
    position: fixed;
    /* overlayの絶対位置を左上を起点にし幅と高さを100%にする */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(160, 160, 160, 0.1);
    /*backdrop-filter: blur(1px);*/
    /* 一番下のbody領域をぼやかす */
    z-index: 1040;
}

.waiting {
    position: absolute;
    top: 474px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 10px;
    border-radius: 50%;
    /* modalに影を作る */
    z-index: 1050;
}

.newPassword {
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0 10px;
    color: darkred;
}