
.main {
    margin: 0 30px 0 50px;
}

.ctrlRow {
    display: flex;
    padding: 0 0 5px 0;
}

.startCol {
    width: 500px;
}

.checkCol {}

.endCol {}

.table {
    width: 680px;
    margin-left: 10px;
    margin-bottom: 10px;
    border-collapse: collapse;
}

.table th {
    color: #868E96;
    border-bottom: solid 1px #F1F1F5;
    font-weight: 400;
    height: 40px;
}

.thId {
    width: 20px;
    text-align: center;
}

.thName {
    width: 200px;
    text-align: center;
}

.thDate {
    width: 150px;
    text-align: start;
    padding-left: 40px;
}

.thLink {
    width: 40px;
    text-align: center;
    cursor: pointer;
}

.table td {
    color: #131523;
    border-bottom: solid 1px #F1F1F5;
    font-size: 1.2rem;
    height: 40px;
    cursor: grab;
}

.table td:active {
   cursor: grabbing !important; 
}

.tdId {
    width: 20px;
    text-align: center;
}

.tdName {
    width: 200px;
    text-align: start;
    padding-left: 20px;
}

.tdDate {
    width: 150px;
    text-align: start;
}

.tdLink {
    width: 40px;
    text-align: center;
    cursor: pointer;
}

.icon {
    color: #007BFF;
    cursor: pointer;
}

.sorting{
    border: none;
    background-color: inherit;
    cursor: grab;
}

.space {
    padding: 4px;
}

.deleted {
    color: red;
    padding-right: 5px;
}

.dragging {
    background-color: #EEE;
}

.deline {
    background-color: #f7f8fb;
}

.button {
    color: #007BFF;
    background-color: white;
    border: solid 1px #007BFF;
    border-radius: 3px;
    padding: 5px 5px;
    outline: none;
}
.button:hover {
    cursor: pointer;
    background-color: #e0effe;
}
.button:focus {
    outline: solid 2px #007BFF80;
}

.nameInput {
    color: #868E96;
    font-size: 1.6rem;
}

.nameInput input {
    width: 400px;
    height: 25px;
    font-size: 1.3rem;
    padding: 0 12px;
    font-weight: 400;
    color: #868D96;
    border: 1px solid #ced4da;
    border-radius: 6px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    letter-spacing: 0.1rem;
}

.nameInput input:focus {
    outline: 2px #007bff solid;
}

.rowOver {
    background-color: #F1F1F5;
    cursor: pointer;
}
