.subTitle {
    font-size: 1.6rem;
    text-align: start;
    margin: 10px 0 10px 50px;
}

.listview {
    width: 1000px;
    margin: 0 30px 0 50px;
}

.accItems {
    padding: 10px 15px;
}

.dsHead {
    display: flex;
    margin: 1px 50px 10px 30px;
    text-align: start;
    border: solid 1px #F1F1F5;
    border-radius: 4px;
    background-color: #F1F1F5;
}

.dsHdLable {
    font-size: 1.3rem;
    color: #868E96;
}

.dsLeft {
    width: 80px;
    padding: 10px 20px;
    font-size: 1.4rem;
}

.nameEdit {
    color: #868E96;
    padding-left: 20px;
    cursor: pointer;
}

.dsMid {
    width: 400px;
    padding: 10px 20px;
    font-size: 1.4rem;
}

.dsRight {
    padding: 10px 20px 1px 20px;
}

.noMapBtn {
    text-align: start;
    margin: 5px 0 0 50px;
}

.table {
    width: 880px;
    margin-left: 40px;
    margin-bottom: 10px;
    border-collapse: collapse;
}

.table th {
    color: #868E96;
    border-bottom: solid 1px #F1F1F5;
    font-weight: 400;
    height: 40px;
}

.th_id {
    width: 50px;
    text-align: center;
}

.th_name {
    width: 150px;
    text-align: center;
}

.th_date {
    width: 80px;
    text-align: start;
    padding-left: 10px;
}

.th_link {
    width: 40px;
    text-align: center;
    cursor: pointer;
}

.table td {
    color: #131523;
    border-bottom: solid 1px #F1F1F5;
    font-size: 1.2rem;
    height: 40px;
}

.tdId {
    width: 50px;
    text-align: center;
}

.tdName {
    width: 200px;
    text-align: start;
    padding-left: 25px;
}

.tdDate {
    width: 80px;
    text-align: start;
}

.tdLink {
    width: 40px;
    text-align: center;
    cursor: pointer;
}

.icon {
    color: #007BFF;
}

.dialogPane {
    width: 520px;
    height: 208px;
    border-radius: 6px;
}

.dialogPaneDel {
    width: 520px;
    height: 280px;
    border-radius: 6px;
}

.dialogHead {
    font-size: 1.8rem;
    padding: 34px 0 22px 57px;
}

.dialogTitle {
    font-size: 1.4rem;
    color: #868D96;
    width: 120px;
    padding: 5px 10px;
}

.dialogData {
    font-size: 1.6rem;
    color: #131523;
}

.dialogBody {
    padding: 0 0 22px 57px;
}

.dialogStDtPan {
    width: 300px;
    padding: 5px;
    display: inline-flex;
}

.dialogStDtLbl {
    width: 50px;
    padding: 10px 10px 0 10px;
}

.dialogStDtPick {
    border: solid 1px #F1F1F5;
    border-radius: 4px;
    width: 235px;
    padding: 4px 0 1px 5px;
}

.nameInput {
    color: #868E96;
    font-size: 1.6rem;
}

.nameInput input {
    width: 400px;
    height: 25px;
    font-size: 1.3rem;
    padding: 0 12px;
    font-weight: 400;
    color: #868D96;
    border: 1px solid #ced4da;
    border-radius: 6px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    letter-spacing: 0.1rem;
}

.nameInput input:focus {
    outline: 2px #007bff solid;
}

.dialogBottom {
    text-align: end;
    padding: 0 41px 0 0px;
}

.spc {
    padding: 10px 15px;
}

.iconBtn {
    color: #007BFF;
    background-color: white;
    border: solid 1px #F1F1F5;
    border-radius: 5px;
    padding: 5px;
}
.iconBtn:hover {
    cursor: pointer;
    background-color: #007bff80;
}
.iconBtn:focus {
    outline: 2px #007bff80 solid;
}

.iconBtnRed {
    color: #F18079;
    background-color: white;
    border: solid 1px #F1F1F5;
    border-radius: 5px;
    padding: 5px;
}

.iconBtnRed:hover {
    cursor: pointer;
    background-color: #F1807980;
}

.iconBtnRed:focus {
    outline: 2px #F1807980 solid;
}

.rowOver {
    background-color: #F1F1F5;
}
