.main {
    
}

.actUp {
    display: flex;
    color: orange;
    position: relative;
    top: 2px;
    /*padding-top: 4px;*/
    width: 14px;
    height: 14px;
}

.nmlUp {
    display: flex;
    color: #868E96;
    position: relative;
    top: 2px;
    /*padding-top: 4px;*/
    width: 14px;
    height: 14px;
}

.actDwn {
    display: flex;
    color: orange;
    position: relative;
    top: -2px;
    width: 14px;
    height: 14px;
}

.nmlDwn {
    display: flex;
    color: #868E96;
    position: relative;
    top: -2px;
    /*padding-top: -3px;*/
    width: 14px;
    height: 14px;
}
