.pane {
    width: 400px;
    border: solid 1px var(--atiko-gray-dde);
    border-radius: 10px;
    background-color: inherit;
    text-align: center;
}

.noPane {
    width: 400px;
    text-align: center;
}

.paneS {
    width: 300px;
    height: 24px;
    border: solid 1px var(--atiko-gray-dde);
    border-radius: 12px;
    background-color: #FFF;
    text-align: center;
}

.noPaneS {
    width: 300px;
    text-align: center;
}

.iconLeft {
    color: var(--atiko-gray-677);
    cursor: pointer;
    padding: 5px;
    
}

.iconLeft svg {
    position: relative;
    top: -4px;
}

.iconLeftS {
    color: var(--atiko-gray-677);
    cursor: pointer;
    padding: 5px;

}

.iconLeftS svg {
    position: relative;
    top: 0px;
}

.main {
    padding: 3px auto;
}

.mainS {
    padding: 2px auto;
}

.iconRight {
    color: var(--atiko-gray-677);
    cursor: pointer;
    padding: 5px;
}

.iconRight svg {
    position: relative;
    top: -4px;
}

.iconRightS {
    color: var(--atiko-gray-677);
    cursor: pointer;
    padding: 5px;
}

.iconRightS svg {
    position: relative;
    top: 0px;
}

.range {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: none;
    height: 14px;
    width: 80%;
    background: #8ACDFF;
    border-radius: 10px;
    border: solid 3px #DFF1FF;
    margin: 0px 0 5px 0;
    padding-bottom: 5px;
}

.range::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #53ADFF;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
}

.range::-moz-range-thumb {
    background: #53ADFF;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: 0px 3px 6x 0px rgba(0, 0, 0, 0.15);
    border: none;
}

.range::-moz-focus-outer {
    border: 0;
}

.range:active::-webkit-slider-thumb {
    box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.3);
}


.rangeS {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: none;
    height: 5px;
    width: 80%;
    background: var(--bs-gray-400);
    border-radius: 4px;
    border: solid 1px var(--bs-gray-500);
    margin: 5px 0 3px 0;
}

.rangeS::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #FFF;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 1px var(--atiko-gray-677);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.rangeS::-moz-range-thumb {
    background: #FFF;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 1px var(--atiko-gray-677);
    box-shadow: 0px 2px 4x 0px rgba(0, 0, 0, 0.15);
}

.rangeS::-moz-focus-outer {
    border: 0;
}

.rangeS:active::-webkit-slider-thumb {
    box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.3);
}