.outer {
}

.range {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: none;
    height: 7px;
    width: 250px;
    background: #d7dbec;
    border-radius: 4px;
    border: solid 1px #d7dbec;
    margin-left: 10px;
}

.range::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #007BFF;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

.range::-moz-range-thumb {
    background: #007BFF;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: none;
}

.range::-moz-focus-outer {
    border: 0;
}

.range:active::-webkit-slider-thumb {

}

.tooltip {

}

.content {
    padding: 4px;
    color: #868e96;
    background-color: white;
    border: 0px solid #eee;
    border-radius: 3px;
    box-shadow: 0px 5px 15px #44444F4F; 
}

.arrow {
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    border-top: 5px solid white;
    width: 0px;
    margin-left: 8px;
}