.mainSng {
    background-color: white;
    width: 1000px;
    margin: 20px 0 15px 35px;
    padding-top: 20px;
}

.mainSngShadow {
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    background-color: white;
    width: 1000px;
    margin: 20px 0 15px 35px;
    padding-top: 20px;
}

.mainCmpShadow {
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    background-color: white;
    width: 1000px;
    margin: 0px 0px 20px 49px;
    padding-top: 15px;
}

.mainCmpLightBlue {
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
    background-color: #D1E6FA35;
    width: 1000px;
    margin: 0px 0px 15px 38px;
    padding-top: 15px;
}

.subtitle {
    display: flex;
    color: #131523;
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0 0 0 50px;
}