.panel {
    width: 1110px;
    height: 960px;
    background-color: white;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
}

.head {
    display: flex;
}

.title {
    flex-basis: 0;
    flex-grow: 1;
    text-align: start;
    font-size: 2.0rem;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 26px;
}

.close {
    padding:10px;
}

.indicator {}

.steps {
    position: relative;
    top: -20px;
    left: 20px;
    text-align: center;
}

.body {
    padding-left: 48px;
}

.frameUp {}

.frameDn {}

.subTitle {
    font-size: 1.6rem;
    font-weight: 400;
    text-align: start;
}

.selector {
    margin: 33px 0 2px 46px;
}

.table {
    padding-top: 30px;
    border-collapse: collapse;
    width: 438px;
    height: 166px;
}

.emptyrow {
    height: 32px;
}

.underlined {
    border-bottom: solid 1px #F1F1F5;
    font-size: 1.2rem;
    color: #131523;
    height: 32px;
}

.tdLabel {
    color: #7E84A3;
    font-size: 1.2rem;
    width: 115px;
    text-align: start;
    padding-left: 3px;
}

.tdinput {}

.nameInput {
    width: 286px;
    height: 28px;
    font-size: 1.2rem;
    padding: 0 12px;
    font-weight: 400;
    color: #868D96;
    border: 1px solid #ced4da;
    border-radius: 6px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    letter-spacing: 0.1rem;
    margin: 0 1px;
}

.nameInput:focus {
    outline: 2px #007bff solid;
}

.tdDate {
    color: #131523;
    font-size: 1.4rem;
}

.tdDatePick {}

.dropdown {
    appearance: none;
    -webkit-appearance: none;
    background-image: url(./img/chevron-down_icon.png);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    border: 1px solid #d7dbec;
    border-radius: 4px;
    color: #868e96;
    width: 430px;
    height: 28px;
    font-size: 1.2rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    padding-left: 15px;
}
.dropdown:focus {
    outline: solid 2px #007bff;
}

.uploader {
    margin-top: 20px;
    margin-left: 166px;
    text-align: start;
}

.useOldConf {
    display: flex;
}

.selectConf {
    height: 40px;
}

.skipCheck {
    margin-left: 20px;
}

.skipReason {
    color:#868D96;
    font-size: 1.2rem;
    margin-left: 20px;
}

.dragArea {
    width: 484px;
    height: 412px;
    border: solid 2px #5A607F;
    box-shadow: 0 0 12px #00000029;
    margin: 12px 0 0 0;
    padding: 5px;
}

.navi {
    position: relative;
    top: -48px;
    left: 730px;
}

.icon {
    display:block;
    position: relative;
    top: -28px;
    left: 385px;
    width: 30px;
    color: #868e96;
}

.preview {
    width: 484px;
    height: 412px;
    object-fit: scale-down;
}

.errDate {
    position: relative;
    top: -28px;
    left: -280px;
    color: #ff0000;
    font-size: 1.2rem;
}

.aster {
    color: #ff0000;
    font-size: 1.2rem;
}