.panel {
    width: 1120px;
    background-color: white;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
}
.top {
    display: flex;
}
.title {
    flex-basis: 0;
    flex-grow: 1;
    text-align: start;
    font-size: 2.0rem;
    font-weight: 700;
    margin-top: 20px;
    margin-left: 40px;
}
.close {
    padding: 10px;
}

.main {
    margin-top: 5px;
    padding: 20px;
}
.head {
    display: flex;
    justify-content: space-between;
    text-align: start;
    margin: 0 0 10px 0;
    padding-left: 50px;
    color: #868E96;
    font-size: 1.2rem;
}

.indicate {
}

.control {
    display: flex;
    margin: 50px 120px 0 0px;
}

.btn1st {
    width: 130px;
}

.btn2nd {
    width: 130px;
}

.row {
    padding: 10px 20px 10px 20px;
}

.label {
    font-size: 1.2rem;
    color: #868E96;
}

.name {
    font-size: 1.4rem;
    padding-left: 10px;
}

.groupSelect {
    appearance: none;
    -webkit-appearance: none;
    background-image: url(./img/chevron-down_icon.png);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    border: 1px solid #d7dbec;
    border-radius: 4px;
    color: #868e96;
    width: 310px;
    height: 29px;
    font-size: 1.2rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    padding-left: 15px;
    margin-left: 10px;
}

.groupSelect:focus {
    outline: 2px #007bff solid;
}


.body {
    display: flex;
    margin: 0 20px 0 50px;
    padding: 0 0 15px 0;
}

.leftSide {
    border: solid 1px #D7DBEC;
        border-radius: 5px;
}

.leftGrpSel {
    margin: 10px;
    padding: 10px;
}

.leftList {
    height: 450px;
    overflow: auto;
}

.mid {
    padding-top: 100px;
    margin: 10px;
}

.rightSide {
    border: solid 1px #D7DBEC;
        border-radius: 5px;
}

.rightGrpSel {
    margin: 10px;
        padding: 10px;
}

.rightList {
    height: 450px;
    overflow: auto;
}

.table {
    width: 430px;
    border-collapse: collapse;
    margin: 0 0 10px 20px;
}

.table caption {
    font-size: 1.6rem;
    font-weight: 600;
    padding: 10px 5px;
}

.table thead {
    font-size: 1.2rem;
    color: #868E96;
}

.table tbody {
    color: #131523;
    font-size: 1.4rem;
}

.table tr {
    border-bottom: solid 1px #D7DBEC;
}

.thChk {
    width: 25px;
}

.table th {
    position: sticky;
    top: 0;
    left: 0;
    padding: 10px;
    background-color: white;
}

.thNo {
    width: 35px;
}

.thName {
    width: 400px;
}

.tdChk {
    width: 25px;
    padding-left: 5px;
}

.tdNo {
    padding: 5px 10px;
    text-align: end;
    width: 35px;
}

.tdName {
    padding: 5px 10px;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 0;
}

.btnArrow {
    color: #007BFF;
    background-color: white;
    border: solid 1px #007BFF;
    border-radius: 3px;
    padding: 5px 5px;
    outline: none;
}
.btnArrow:hover {
    cursor: pointer;
    background-color: #e0effe;
}
.btnArrow:focus {
    outline: solid 2px #007BFF80;
}
.btnMove {
    padding: 10px;
}

.rowOver {
    background-color: #F1F1F5;
}
