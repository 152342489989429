.panel {
    width: 1100px;
    height: 670px;
    background-color: white;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
}

.head {
    display: flex;
}

.title {
    flex-basis: 0;
    flex-grow: 1;
    text-align: start;
    font-size: 2.0rem;
    font-weight: 700;
    margin-top: 30px;
    margin-left: 36px;
}

.close {
    padding: 10px;
}

.body {

}

.list {
    margin: 5px 0 0 85px;
    text-align: start;
    flex-wrap: nowrap;
    flex-basis: 0;
}

.buttons {
    flex-wrap: nowrap;
    flex-grow: 1;
    text-align: end;
    margin-top: 5px;
    margin-right: 80px;
}

.saveBtn {
    display: flex;
    justify-content: flex-end;
}

.table {
    width: 550px;
    border-collapse: collapse;
}

.dtTitle {
    width: 94px;
    border-bottom: solid 1px #F1F1F5;
    color: #7E84A3;
    font-size: 1.2rem;
    height: 25px;
    padding: 1px 10px 1px 10px;
}

.dtName {
    width: 280px;
    border-bottom: solid 1px #F1F1F5;
    color: #131523;
    font-size: 1.4rem;
}

.dtInput {
    width: 280px;
    border-bottom: solid 1px #F1F1F5;
    color: #131523;
    font-size: 1.4rem;
}

.dtDate {
    /*width: 421px;*/
    border-bottom: solid 1px #F1F1F5;
    color: #131523;
    font-size: 1.4rem;
}

.dtInput input {
    width: 300px;
    height: 25px;
    font-size: 1.3rem;
    padding: 0 12px;
    font-weight: 400;
    color: #868D96;
    border: 1px solid #ced4da;
    border-radius: 6px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    letter-spacing: 0.1rem;
}

.dtInput input:focus {
    outline: 2px #007bff solid;
}

.startDate {
    width: 210px;
    border: solid 1px #d7dbec;
    border-radius: 4px;
    padding: 0;
}

.mapArea {
    display: flex;
}

.leftSide {
    width: 550px;
    height: 440px;
    border: solid 2px #5A607F;
    margin: 10px 5px 0 86px;
}

.rightSide {
    width: 440px;
}

.canvasWrapper {
    position: relative;
    box-sizing: content-box;
    width: 550px;
    height: 440px;
}

.canvasWrapper::before {
    content: "";
    display: block;
    padding-top: 0%;
}

.canvasLeft {
    z-index: 1;
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
}

.canvasCell {
    z-index: 6;
    position: absolute;
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
}

.canvasAnim {
    z-index: 11;
    position: absolute;
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
}

.rightTable {
    width: 410px;
    border-collapse: collapse;
    margin-top: 30px;
    margin-left: 10px;
}

.rightTable tr {
    height: 35px;
}

.rightTable td {
    border-bottom: solid 1px #F1F1F5;
    color: #7E84A3;
    font-size: 1.2rem;
    padding: 10px 0 5px 0;
    text-align: start;
}

.rtTitle {
    width: 140px;
    font-size: 1.2rem;
    text-align: start;
}

.rtTitle2 {
    width: 100px;
    font-size: 1.2rem;
}

.numberInput {
    width: 40px;
    height: 25px;
    font-size: 1.3rem;
    padding: 0 12px;
    font-weight: 400;
    color: #868D96;
    border: 1px solid #ced4da;
    border-radius: 6px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    letter-spacing: 0.1rem;
    margin: 0 10px;
}
.numberInput:focus {
    outline: 2px #007bff solid;
}

.fileInput {
    width: 200px;
    height: 25px;
    font-size: 1.3rem;
    padding: 0 12px;
    font-weight: 400;
    color: #868D96;
    border: 1px solid #ced4da;
    border-radius: 6px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    letter-spacing: 0.1rem;
    margin: 0 1px;
}
.fileInput:focus {
    outline: 2px #007bff solid;
}

.bottom {


}

.confirm {
    color: #868E96;
    font-size: 1.6rem;
}

.spc {
    padding: 10px 15px;
}

.spc3 {
    padding: 10px 150px;
}
.spc4 {
    padding: 10px 100px;
}

.preview {
    width: 450px;
    height: 400px;
    object-fit: scale-down;
}

.table td p {
    margin: 3px 0 4px 10px;
}

.errorMessage {
    font-size: 1.0rem;
    color: red;
}

.eMessage {
    font-size: 1.0rem;
    color: #7000cb;
}

.zoomBtn {
    z-index: 12;
    position: absolute;
    top: 0;
    right: 233px;
}

.fileupload {
    z-index: 13;
    position: absolute;
    top: 0;
    right: 75px;
}

.checkbox {
    margin: 5px 0 5px 15px;
}