.pickerLg {
    border-radius: 4px;
    border: solid 1px #D7DBEC;
    padding: 0px 150px 3px 0px;
    color: #868E96;
}
.datelabelLg {
    font-size: 2.0rem;
    font-weight: 400;
    color: #5A607F;
}
.iconLg {
    width: 24px;
    height: 24px;
    position: relative;
    left: 150px;
    top: 4px;
}

.pickerSm {
    padding: 2px 1px 5px 15px;
    color: #868E96;
}
.datelabelSm {
    font-size: 1.4rem;
    vertical-align: 0.6rem;
}
.iconSm {
    width: 24px;
    height: 24px;
    position: relative;
    left: 15px;
    top: 1px;
}

.pickerMc {
    padding: 2px 1px 0px 15px;
    color: #868E96;
}

.datelabelMc {
    font-size: 1.4rem;
    vertical-align: 0.6rem;
    cursor: pointer;
}

.iconMc {
    width: 24px;
    height: 24px;
    position: relative;
    left: 15px;
    top: 1px;
}