.panel {
    width: 1110px;
    height: 700px;
    background-color: white;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
}

.head {
    display: flex;
}

.title {
    flex-basis: 0;
    flex-grow: 1;
    text-align: start;
    font-size: 2.0rem;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 26px;
}

.close {
    padding: 10px;
}

.indicator {}

.steps {
    position: relative;
    top: -20px;
    left: 20px;
    text-align: center;
}

.body {
    padding-left: 45px;
    text-align: start;
}

.subTitle {
    font-size: 1.6rem;
    font-weight: 400;
    text-align: start;
}

.uploader {
    width: 484px;
    height: 412px;
    border: solid 2px #5A607F;
    box-shadow: 0 0 12px #00000029;
    margin: 26px 0 0 166px;
    padding: 5px;
}

.navi {
    position: relative;
    top: -10px;
    left: 890px;
}

.preview {
    width: 484px;
    height: 412px;
    object-fit: scale-down;
}

.back {
    position: relative;
    top: -40px;
    left: 35px;
    width: 100px;
}