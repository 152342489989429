.pane {
    display: inline-block;
}

.subs {
    margin-top: 19px;
    margin-bottom: 18px;
    color: #5A607F;
}

.subtitle {
    margin-left: 30px;
    font-weight: 700;
    font-size: 1.4rem;
    /*color: #868e96;*/
}

.blueCircle {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #007bff;
    margin: 0px 5px 0px 0px;
}

.greenCircle {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #29CB97;
    margin: 0px 5px 0px 0px;
}

.main {
    margin: 5px 40px 30px 50px;
}

.main2 {
    margin: 5px 15px 15px 50px;
    padding-right: 84px;
    border-right: 1px solid #d7dbec;
}

.shopSel {
    margin: 9px 0px;
}

.mapSel {
    margin: 9px 0px;
}

.areaSel {
    margin: 9px 0px;
}

.productSel {
    margin: 7px 0px;
}

.customSelect {
    appearance: none;
    -webkit-appearance: none;
    background-image: url(./img/chevron-down_icon.png);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    border: 1px solid #d7dbec;
    border-radius: 4px;
    color: #868e96;
    width: 421px;
    height: 29px;
    font-size: 1.2rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    padding-left: 15px;
}

.customSelect:focus {
    outline: 2px #007bff solid;
}

.dateSpan {
    margin: 5px 0px 10px 0px;
}

.timeSpan {
    margin: 5px 0px 10px 0px;
}

.dateRange {
    border: solid 1px #d7dbec;
    border-radius: 4px;
    width: 65%;
    padding: 1px 0px 1px 0px;
    margin: 5px 0px 5px 0px;
}

.startDateInput {
    outline: none;
    border-width: 0;
    background-image: url(./img/calendar_icon.png);
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: right;
    width: 90px;
    height: 30px;
    padding: 0px 10px 0px 15px;
    color: #868e96;
    font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.2rem;
    cursor: pointer;
}

.connect {
    display: inline-block;
    color: #868e96;
    vertical-align: 0.3rem;
    font-weight: 500;
    padding-left: 9px;
}

.endDateInput {
    outline: none;
    border-width: 0;
    background-image: url(./img/calendar_icon.png);
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: right;
    width: 90px;
    height: 30px;
    padding: 0px 10px 0px 12px;
    color: #868e96;
    font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.2rem;
    cursor: pointer;
}

.timeRange {
    border: solid 1px #d7dbec;
    border-radius: 4px;
    width: 47%;
    padding: 0px 0px 0px 3px;
    margin: 4px 0px 4px 0px;
    display: inline-block;
}

.startTimeInput {
    outline: none;
    border-width: 0;
    background-image: url(./img/clock_icon48.png);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: right;
    width: 52px;
    height: 30px;
    padding: 0px 10px 0px 12px;
    color: #868e96;
    font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.2rem;
    cursor: pointer;
}

.endTimeInput {
    outline: none;
    border-width: 0;
    background-image: url(./img/clock_icon48.png);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: right;
    width: 52px;
    height: 30px;
    padding: 0px 10px 0px 12px;
    color: #868e96;
    font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.2rem;
    cursor: pointer;
}

.timeErrMsg {
    display: inline-block;
    width: none;
    color: red;
    font-size: 1.1rem;
    font-weight: 400;
    padding-left: 5px;
}

.threshold {
    margin: 9px 0px 10px 0px;
}

.numpic {
    margin: 5px 0px 10px 0px;
}