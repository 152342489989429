.content {
    text-align: start;
}

.single {
    width: 836px;
    margin: 20px 0 10px 50px;
    /*box-shadow: 0px 1px 4px #00000033;*/
    border: 1px solid #00000033;
    border-radius: 4px;
    padding: 0px 0 10px 0;
}

.compare {
    width: 709px;
    height: 329px;
    margin-left: 110px;
    margin-top: 40px;
    padding-bottom: 15px;
    box-shadow: 0px 1px 4px #15223214;
}

.head {
    display: flex;
    margin: 13px 90px 0px 85px;
    padding-top: 31px;
}

.headCmp {
    display: flex;
    margin: 13px 8px 0px 35px;
    padding-top: 10px;
}

.title {
    justify-content: start;
    color: #131523;
    font-family: 'Poppins',
        'Noto Sans JP',
        sans-serif;
    font-weight: 600;
    font-size: 1.8rem;
}

.dummy {
    margin: 0 auto;
}

.switch {
    padding: 11px 0 0 0;
    justify-content: end;
}

.graph {
    width: 580px;
    margin: 13px 0 0 88px;
    border-radius: 4px;
    padding-bottom: 10px;
}

.graphCmp {
    width: 560px;
    margin: 1px 0 0 40px;
    border-radius: 4px;
    padding-bottom: 10px;
}

.list {
    
}

.caption {
    color: #212529;
    font-size: 2.0rem;
    font-weight: 600;
    padding: 50px 0 10px 50px;
}

.captionCmp {
    color: #212529;
    font-size: 2.0rem;
    font-weight: 600;
    padding: 50px 0 10px 108px;
}

.tablePane {
    padding: 0px 0 28px 120px;
}

.tablePaneCmp {
    padding: 0px 0 28px 180px;
}

.table {
    border-collapse: collapse;
    width: 750px;
}

.table tr {
    height: 20px;
}

.table th {
    color: #868E96;
    font-weight: 400;
    border-bottom: solid 1px #F1F1F5;
}

.table td {
    border-bottom: solid 1px #F1F1F5;
    color: #131523;
    font-size: 1.4rem;
}

.thDate {
    text-align: center;
    padding-left:0px;
}

.thNum {
    text-align: end;
    padding-right: 21px;
}

.thRatio {
    text-align: end;
    padding-right: 21px;
}

.tdDate {
    width: 120px;
    text-align: start;
    padding-left: 20px;
}

.tdVisi {
    width: 100px;
    text-align: end;
    padding-right: 20px;
}

.tdCust {
    width: 100px;
    text-align: end;
    padding-right: 20px;
}

.tdPurc {
    width: 100px;
    text-align: end;
    padding-right: 20px;
}

.tdCsRt {
    width: 90px;
    text-align: end;
    padding-right: 20px;
}

.tdPrRt {
    width: 90px;
    text-align: end;
    padding-right: 20px;
}