.dialogPane {
    width: 600px;
    height: 180px;
    border-radius: 6px;
}

.dialogHead {
    font-size: 1.8rem;
    padding: 34px 0 22px 57px;
}

.dialogHeadRight {
    font-size: 1.8rem;
    padding: 34px 0 10px 57px;
}

.dialogBody {
    padding: 0 0 22px 57px;
}

.confirm {
    color: #868E96;
    font-size: 1.6rem;
}

.dialogBottom {
    text-align: end;
    padding: 0 41px 0 0px;
}

.digBtmSng {
    text-align: center;
}

.dialogBottomRight {
    position: relative;
    top: -60px;
    left: 440px;
}

.spc {
    padding: 10px 15px;
}