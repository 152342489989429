.map {
    display: flex;
}

.leftmap {
    width: 450px;
    height: 400px;
    border: solid 2px #5A607F;
    box-shadow: inset 0 0 12px #00000029;
    margin: 10px 5px 0 28px;
    padding: 5px;
}

.rightmap {
    width: 450px;
    height: 400px;
    border: solid 2px #5A607F;
    box-shadow: inset 0 0 12px #00000029;
    margin: 10px 0 0 0;
    padding: 5px;
}

.preview {
    width: 450px;
    height: 400px;
    object-fit: scale-down;
}

.arrow {
    padding: 200px 5px 0 5px;
}

.dialogBottom {
    display: flex;
    padding: 10px;
}

.spc {
    padding: 10px 15px;
}

.spc5 {
    padding: 10px 360px;
}

.leftbadge {
    position: relative;
    top: -400px;
    left: 8px;
}

.roundcorner {
    font-family: 'Helvetica Neue', 'Noto Sans JP', sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    background-color: #6C757D;
    color: white;
    border-radius: 10px;
    text-align: center;
    letter-spacing: 1px;
    padding: 1px 7px 2px 7px;
}

.rightbadge {
    position: relative;
    top: -400px;
    left: 8px;
}

.badgeBlue {
    width: 133px;
    height: 14px;
    background-color: #007BFF;
    border-radius: 10px;
    font-family: 'Helvetica Neue', 'Noto Sans JP', sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    color: white;
    text-align: center;
    padding: 0 7px 2px 7px;
    letter-spacing: 0px;
    margin: 5px 0 0 3px;
}