.pane {
    display: block;
    text-align: start;
    width: 270px;
    height: 60px;
    margin: 0 auto;
    background-color: #D7DBEC;
    border: solid 1px #D7DBEC;
    border-radius: 10px;
    font-size: 1.2rem;
    color: #131523;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.primary {
    padding: 10px 0px 0px 45px;
}

.secondary {
    padding: 3px 0px 0px 45px;
}

.spinner {
    position: relative;
    top: -30px;
    left: 230px;
}

