.main {
    width: 1120px;
}

.head {
    margin: 6px 21px 50px 26px;
    display: flex;
    font-weight: 700;
}

.info {
    flex-basis: 0;
    flex-grow: 1;
    width: 200px;
}

.custId {
    margin-top: 11px;
}

.custTime {
    padding-left: 11px;
}

.custLabel {
    padding-right: 24px;
}

.posRegi {
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    margin-top: 11px;
    padding-left: 50px;
}

.amount {
    width: 210px;
}

.itemCnt {
    width: 210px;
}

.buyLabel {
    padding-right: 10px;
}

.close {
    padding-top: 2px;
    padding-right: 2px;
    display: flex;
    justify-content: flex-end;
}

.list {
    display: flex;
    margin-left: 83px;
    margin-bottom: 36px;
}

.listLeft {
    margin-right: 100px;
}

.listRight {
    margin-right: 100px;
}

.table {
    width: 411px;
    border-collapse: collapse;
}

.table thead tr {
    color: #5A607F;
    font-weight: 400;
    font-size: 1.2rem;
}

.table tbody tr {
    font-size: 1.2rem;
}

.cap {
    border-bottom: 1px solid #E6E9F4;
    font-weight: 700;
    margin-bottom: 10px;
}

.listRow {
    height: 27px;
}

.thID {
    width: 24px;
    font-weight: 400;
    padding: 0 5px;
}

.thName {
    width: 219px;
    font-weight: 400;
    padding: 0 5px;
}

.thTime {
    width: 70px;
    font-weight: 400;
    padding: 0 5px;
}

.thCount {
    width: 60px;
    font-weight: 400;
    padding: 0 5px;
}

.tdID {
    width: 1;
    text-align: right;
    padding: 0 5px;
}

.tdName {
    max-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 5px;
}

.tdCount {
    width: 1;
    padding: 0 10px;
    text-align: right;
}

.tdTime {
    width: 1;
    padding: 0 5px;
    text-align: center;
}

.rank {
    margin-left: 64px;
    margin-right: 50px;
    margin-bottom: 60px;
    font-family: "Helvetica Neue",
            Arial,
            "Hiragino Kaku Gothic ProN",
            "Hiragino Sans",
            Meiryo,
            sans-serif;
    font-weight: 400;
    font-size: 1.6rem;
    border-radius: 4px;
    box-shadow: 0 1px 4px #15223214;
}

.rankTbl {
    margin-left: 57px;
    margin-right: 57px;
    padding-bottom: 44px;
    padding-top: 24px;
}

.rankTbl thead tr {
    color: #5A607F;
    font-weight: 400;
    font-size: 1.2rem;
    height: 60px;
}

.rankTbl thead th {
    font-weight: 400;
    border-bottom: solid 1px #E6E9F4;

}

.rankTbl tbody td {
    font-size: 1.3rem;
    border-bottom: solid 1px #E6E9F4;
    height: 35px;
}

.rkhName {
    text-align: left;
    padding-left: 10px;
    width: 240px;
}

.rkhTime {
    width: 100px;
}

.rkhCount {
    width: 100px;
}

.rkhGraph {
    width: 360px;
}


.rkdName {
    max-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 10px;
}

.rkdCount {
    width: 1;
    text-align: center;
    font-weight: 700;
}

.rkdTime {
    width: 1;
    padding: 0 10px;
    text-align: right;
    font-weight: 500;
}

.rkdGraph {
    width: 1;
    padding: 0 20px;
}

.memo {
    height: 10px;
}

