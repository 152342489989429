.pack {
    margin-bottom: 0px !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.rectOn {
    
    color: #868E96;
    /*background-color: white;*/
    background-image: url('./img/rect_check_on.png');
    background-repeat: no-repeat;
    background-position: 0px;
    background-size: 14px;
}

.rectOff {
    
    color: #868E96;
    /*background-color: white;*/
    background-image: url('./img/rect_check_off.png');
    background-repeat: no-repeat;
    background-position: 0px;
    background-size: 14px;
}

.circleOn {
    
    color: #868E96;
    background-color: white;
    background-image: url('./img/circle_check_on.png');
    background-repeat: no-repeat;
    background-position: 0px;
    background-size: 14px;
}

.circleOff {
    
    color: #868E96;
    background-color: white;
    background-image: url('./img/circle_check_off.png');
    background-repeat: no-repeat;
    background-position: 0px;
    background-size: 14px;
}

.label {
    margin: 2px 0px 2px 20px;
    
}

.posCursor {
    cursor: pointer;
    padding-bottom: 2px;
}

.notAlwCursor {
    cursor: not-allowed;
    opacity: 0.5;
}
