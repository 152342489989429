
.table {
    width: 800px;
    margin: 0 40px 10px 50px;
    border-collapse: collapse;
}

.table th {
    color: #868E96;
    border-bottom: solid 1px #F1F1F5;
    font-weight: 400;
    height: 45px;
}

.th_id {
    width: 96px;
    text-align: center;
}
.th_name {
    width: 302px;
    text-align: center;
}
.th_date {
    width: 170px;
    text-align: start;
    padding-left: 40px;
}
.th_link {
    width: 50px;
    text-align: center;
    cursor: pointer;
}

.table td {
    color: #131523;
    border-bottom: solid 1px #F1F1F5;
    font-size: 1.4rem;
    height: 45px;
}
.tdId {
    width: 96px;
    text-align: center;
}

.tdName {
    width: 302px;
    text-align: start;
    padding-left: 25px;
}

.tdDate {
    width: 170px;
    text-align: start;
}

.tdLink {
    width: 50px;
    text-align: center;
}

.icon {
    color: #007BFF;
    cursor: pointer;
}

.iconDisabled {
    color: #868E9680;
    cursor: not-allowed;
}

.newEntryPane {
    width: 567px;
    height: 208px;
    border-radius: 6px;

}

.newEntryHead {
    font-size: 1.8rem;
    padding: 34px 0 22px 57px;
}

.newEntryBody {
    padding: 0 0 22px 57px;
}

.input {
    width: 469px;
    height: 29px;
    font-size: 1.6rem;
    padding: 0 12px;
    font-weight: 400;
    color: #868D96;
    border: 1px solid #ced4da;
    border-radius: 6px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    letter-spacing: 0.1rem;
}

.input:focus {
    outline: 2px #007bff solid;
}

.newEntryBottom {
    text-align: end;
    padding: 0 41px 0 0px;
}

.spc {
    padding: 10px 15px;
}

.rowOver {
    background-color: #F1F1F5;
    cursor: pointer;
}