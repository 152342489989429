.app {
    text-align: center;
    width: 100%;
    height: 100vh;
    /*margin-left: auto;
    margin-right: auto;*/
}

.main {
    display: flex;
    padding: 15px;
    /*width: 1336px;*/
}

.timeout {
    width: 320px;
    height: 140px;
    text-align: center;
    padding: 20px;
}

.message {
    padding-bottom: 30px;
}

.menu {
    flex-basis: 210px;
    
}

.hidemenu {
    width: 200px;
}

.contents {
    flex-basis: auto;
    /*flex-grow: 1;
    height: 100%;*/
}

.foot {
    position: sticky;
    top: 100vh;
    width: 100%;
}
