.panel {
    width: 1050px;
    height: 690px;
    background-color: white;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
}

.head {
    display: flex;
}

.title {
    flex-basis: 0;
    flex-grow: 1;
    text-align: start;
    font-size: 2.0rem;
    font-weight: 700;
    margin-top: 30px;
    margin-left: 35px;
}

.close {
    padding: 10px;
}

.body {
    display: flex;
    text-align: start;
}

.leftside {
    margin-left: 60px;
}

.lefttop {}

.list {
    margin: 5px 0 0 85px;
    height: 115px;
    text-align: start;
    flex-wrap: nowrap;
    flex-basis: 0;
}

.table {
    width: 440px;
    border-collapse: collapse;
}

.lsHead {
    width: 94px;
    border-bottom: solid 1px #F1F1F5;
    color: #7E84A3;
    font-size: 1.2rem;
    height: 25px;
}

.lsData {
    width: 421px;
    border-bottom: solid 1px #F1F1F5;
    color: #131523;
    font-size: 1.4rem;
}

.leftbtm {
    margin-top: 21px;

}

.section {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 28px;
}

.legend {
    margin-top: 20px;
}

.unregistered {
    margin: 19px 0 9px 0px;
}

.yellow {
    color: white;
    font: 0.8rem "Poppins";
    font-weight: 600;
    background: rgb(255, 199, 0);
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 5px;
    padding: 2px;
}

.text {
    font-size: 1.2rem;
    margin: 10px 0 5px 0;
    padding: 0px 0 15px 0;
    vertical-align: middle;
    color: #5A607F;
}

.map {
    width: 620px;
    margin-left: 40px;
}

.canvasWrap {
    position: relative;
    padding: 0;
    margin: 0;
    box-sizing: content-box;
    width: 620px;
}

.canvasWrap::before {
    content: "";
    display: block;
    padding-top: 0%;
}

.canvas {
    z-index: 1;
    position: absolute;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
}

.canvasBorder {
    border: solid 1px black;
}

.aniCanvas {
    z-index: 11;
    position: absolute;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    border: solid 1px #F1F1F5;
    border-radius: 5xp;
}

.rightside {
    margin-top: 55px;
    margin-left: 10px;
    width: 300px;
}

.rightTop {
    width: 250px;
    display: flex;
    margin: 20px 0 40px 15px;
    height: 45px;
}

.rightMid {
    width: 295px;
    padding: 5px;
    margin: 5px 0 10px 5px;
    border-bottom: solid 1px #F1F1F5;
}

.startDate {
    display: flex;
}

.startLabel {
    color: #7E84A3;
    font-size: 1.2rem;
    padding: 7px 12px 1px 0px;
}

.startBorder {
    border: solid 1px #F1F1F5;
    border-radius: 4px;
    width: 215px;
    cursor: pointer;
}

.rightBottom {
}

.buttons {
    flex-wrap: nowrap;
    flex-grow: 1;
    text-align: start;
    /*border: solid 1px #F1F1F5;
    border-radius: 5px;*/
    padding: 30px;
    height: 320px;
}

.btnRow1st {
    display: flex;
    height: 43px;
}

.space {
    width: 24px;
}

.spaceW {
    width: 110px;
}

.btnRow2nd {
    margin-top: 20px;
    height: 43px;
}

.btnRow3rd {
    margin-top: 20px;
}

.btnBottom {
    margin-top: 130px;
}

.zoomBtn {
    z-index: 12;
    position: absolute;
    top: 0;
    right: 265px;
}

.areaInformation {
    z-index: 15;
    position: absolute;
    font-size: 1.0rem;
    color: white;
    background-color: #7E84A3A0;
    border-radius: 4px;
    padding: 5px;
    left: -1000px;
}

.areaNumber {

}

.areaName {

}

.areaType {

}

.memo{
    position: absolute;
    top: 346px;
    left: 320px;
}

.memoText{
    color: #7E84A3;
    font-size: 1.2rem;
}

.modalMain {
    padding: 18px 15px 0px 41px;
    margin-bottom: 2px;
}

.top {
    display: flex;
}

.title {
    padding-top: 2px;
    font-size: 20px;
    font-weight: 600;
    flex-basis: 0;
    flex-grow: 1;
}

.closer {
    padding-top: 2px;
    padding-right: 2px;
    display: flex;
    justify-content: flex-end;
}

.modalBody {
    font-size: 14px;
    color: #131523;
    margin-left: 28px;
    margin-right: 15px;
}

.modalTable {
    max-width: 1262px;
    margin: 0 10px 10px 80px;
    justify-content: center;
    text-align: center;
    border-collapse: collapse;
}
.modalTable th {
    padding: 0px 10px 8px 10px;
    font-size: 12px;
    font-weight: 300;
    color: #7E84A3;
}

.modalTable td {
    padding: 8px 15px;
    border-bottom: solid 1px #F1F1F5;
}

.thId {
    width: 70px;
    margin: 0 15px;
}

.thName {
    width: 370px;
}

.thLink {
    width: 70px;
}

.dataColEllipsis {
    max-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.chevron {
    color: #007bff;
    cursor: pointer;
}

.dialogPane {
    width: 600px;
    height: 180px;
    border-radius: 6px;
}

.dialogHead {
    font-size: 1.8rem;
    padding: 34px 0 22px 57px;
}

.dialogBody {
    padding: 0 0 22px 57px;
}

.confirm {
    color: #868E96;
    font-size: 1.6rem;
}

.dialogBottom {
    text-align: end;
    padding: 0 41px 0 0px;
}

.spc {
    padding: 10px 15px;
}

.areaSelect {
    position: relative;
    top: 0px;
    left: 0px;
}

.itemSelect {
    position: absolute;
    top: 46px;
    left: -263px;
    background: white;
    font-size: 1.2rem;
    width: 280px;
    max-height: 400px;
    border: solid 1px #7E84A3;
    border-radius: 4px;
    padding:5px;
    color: #868E96;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

}

.listSelect {
    list-style: none;
    padding-inline-start: 0;
    margin-block-start: 10px;
    margin-block-end: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.listSelect li {
    padding: 5px;
}

.listSelect li:hover {
    background-color: rgba(0, 98, 255, 0.05);
    cursor: pointer;
}

.rowOver {
    background-color: #F1F1F5;
    cursor: pointer;
}
