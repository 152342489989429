.panel {
    width: 1120px;    
    background-color: white;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
    z-index: 1000;
}

.top {
    display: flex;
}

.title {
    flex-basis: 0;
    flex-grow: 1;
    text-align: start;
    font-size: 2.0rem;
    font-weight: 700;
    margin-top: 30px;
    margin-left: 40px;
}

.close {
    padding: 10px;
}

.main {
    margin-top: 5px;
    padding: 20px;
}

.head {
    text-align: start;
    padding-left: 74px;
    color: #868E96;
    font-size: 1.2rem;
}

.heading {
    text-align: start;
    margin: 20px 0 0 0;
    padding-left: 55px;
    color: #868E96;
    font-size: 1.2rem;
}

.choice {
    margin: 10px 0 10px 4px;
    padding-left: 30px;
    color: #868E96;
    font-size: 1.2rem;
}

.row {
    padding: 16px 0px 4px 20px;
}

.label {
    font-size: 1.2rem;
}

.txtLabel {
    font-size: 1.2rem;
    padding-right: 10px;
}

.name {
    font-size: 1.4rem;
}

.body {
    display: flex;
    text-align: start;
    padding-left: 40px;
}

.left {
    width: 50%;
}

.groupSelect {
    appearance: none;
    -webkit-appearance: none;
    background-image: url(./img/chevron-down_icon.png);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    border: 1px solid #d7dbec;
    border-radius: 4px;
    color: #868e96;
    width: 375px;
    height: 29px;
    font-size: 1.2rem;
    font-family: 'Noto Sans JP',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    padding-left: 15px;
}

.groupSelect:focus {
    outline: 2px #007bff solid;
}

.subtitle {
    font-size: 1.6rem;
    font-weight: 600;
    padding: 10px 5px;
}

.arealist {
    
}

.table {
    width: 450px;
    margin: 0 10px 5px 20px;
    border-collapse: collapse;
}

.table thead tbody {
    display: block;
}

.table thead {
    font-size: 1.2rem;
    color: #868E96;
}

.table tbody {
    overflow-x: hidden;
    overflow-y: scroll;
    color: #131523;
    font-size: 1.4rem;
}

.table tr {
    border-bottom: solid 1px #F1F1F5;
}

.table th {
    padding: 10px;
}

.thChk {
    width: 25px;
}
.thNo {
    width: 35px;
}
.thName {
    width: 300px;
}

.tdChk {
    justify-content: center;
}

.tdNo {
    padding: 5px 10px;
    text-align: end;
}

.tdName {
    padding: 5px 10px;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 0px;
}

.tdColsp {
    text-align: center;
    padding: 20px;
}


.right {
    width: 50%;
}

.buttons {
    display: flex;
}

.leftBtn {
    margin: 0 10px 5px 100px;
}

.rightBtn {
    margin: 0 10px 5px 10px;
}

.centerBtn {
    margin: 0 10px 5px 100px;
}

.lowerBtn {
    margin: 5px 10px 20px 100px;
}

.map {
    margin: 10px;
}

.wrap {
    position: relative;
    padding: 5px;
    box-sizing: content-box;
    width: 450px;
    border: solid 2px #5A607F;
    border-radius: 6px;
}

.canvas {
    z-index: 1001;
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
}

.aniCanvas {
    z-index: 1010;
    position: absolute;
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    top: 5px;
    left: 5px;
}

.zoomBtn {
    z-index: 1012;
    position: absolute;
    top: 5px;
    right: 180px;
}

.rowOver {
    background-color: #F1F1F5;
}
