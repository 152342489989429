.nameInput {
    color: #868E96;
    font-size: 1.6rem;
}

.nameInput input {
    width: 400px;
    height: 25px;
    font-size: 1.3rem;
    padding: 0 12px;
    font-weight: 400;
    color: #868D96;
    border: 1px solid #ced4da;
    border-radius: 6px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    letter-spacing: 0.1rem;
}

.nameInput input:focus {
    outline: 2px #007bff solid;
}

